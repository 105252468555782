import React from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';

import Heading from '../../shared/components/Heading';
import Main from '../../shared/components/layout/Main';

const AuthenticatedNotFoundPage = ({ message }) => {
    const pageTitle = 'CollaboMate';

    return (
        <div className="authenticated-not-found-container">
            <Helmet title={pageTitle} />
            <Main hasHeader={false} hasNavigation={false} hasHeading={false}>
                <Heading size={4} text={message ?? 'Something went wrong'} />
                <Link to={STATIC_ROUTES_PATHS.main}>Go back to Home</Link>
            </Main>
        </div>
    );
};

AuthenticatedNotFoundPage.propTypes = {
    message: PropTypes.string
};

export default AuthenticatedNotFoundPage;
