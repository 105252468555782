import React from 'react';

import PropTypes from 'prop-types';

import { marineBlue } from '../../../constants/generalConstants';

const KeyboardArrowLeft = ({ size = 24, color = marineBlue, className }) => {
    return (
        <svg
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 15.875L11.12 11.995L15 8.11502C15.39 7.72502 15.39 7.09502 15 6.70502C14.8132 6.51777 14.5595 6.41254 14.295 6.41254C14.0305 6.41254 13.7769 6.51777 13.59 6.70502L9.00002 11.295C8.61002 11.685 8.61002 12.315 9.00002 12.705L13.59 17.295C13.98 17.685 14.61 17.685 15 17.295C15.38 16.905 15.39 16.265 15 15.875Z"
                fill="#012840"
            />
        </svg>
    );
};

KeyboardArrowLeft.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
};

export default KeyboardArrowLeft;
