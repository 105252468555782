import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import workLocationService from '../../wfh/services/workLocationsService';

const initialState = {
    workLocations: []
};

export const createWorkLocation = createAsyncThunk(
    'workLocations/createWorkLocation',
    async (workLocation, thunkAPI) => {
        let workLocationModel = {
            workLocationTypeId: workLocation.workLocationTypeId,
            startDate: workLocation.startDate,
            deskId: workLocation.deskId
        };

        const workLocationResponse =
            await workLocationService.createWorkLocation(workLocationModel);

        return workLocationResponse;
    }
);

export const updateWorkLocation = createAsyncThunk(
    'workLocations/updateWorkLocation',
    async (workLocation, thunkAPI) => {
        let workLocationModel = {
            workLocationTypeId: workLocation.workLocationTypeId,
            startDate: workLocation.startDate,
            deskId: workLocation.deskId
        };

        const workLocationResponse =
            await workLocationService.updateWorkLocation(
                workLocation.id,
                workLocationModel
            );

        return workLocationResponse;
    }
);

const { reducer: workLocationsReducer, actions } = createSlice({
    name: 'workLocations',
    initialState,
    reducers: {}
});

export { workLocationsReducer, actions as workLocationsActions };
