import styled from 'styled-components';

const StyledLocationPicker = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 8px 0;

    .option:hover {
        cursor: pointer;
        background-color: var(--marine5);
        border-radius: 2px;
    }

    .option {
        margin-bottom: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
    }

    .option:last-child {
        margin-bottom: 0px;
    }

    .work-from {
        font-weight: 600;
        font-size: 14px;
        color: var(--fauxBlack);
        line-height: 129%;
    }

    .disabled {
        color: var(--grey);
    }
`;

export default StyledLocationPicker;
