import { useEffect, useState } from 'react';

import { Empty } from 'antd';
import { useParams } from 'react-router-dom';
import Spinner from 'shared/components/Spinner';
import layoutService from 'wfh/services/layoutService';

import StyledLayoutPage from './styled/LayoutPageStyled';

const LayoutPage = () => {
    const [layout, setLayout] = useState();
    const [label, setLabel] = useState();
    const { deskId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await layoutService.getLayoutByDeskId(deskId);
                const blobLayout = new Blob([response.file], {
                    type: 'image/svg+xml'
                });
                setLayout(blobLayout);
                setLabel(response.label);
            } catch {
                setError(true);
            }
            setIsLoading(false);
        }
        fetchData();
    }, []);

    return (
        <StyledLayoutPage>
            <Spinner spinning={isLoading}>
                <div className="layout-label">{label}</div>
                {layout && (
                    <iframe
                        className="layout"
                        src={URL.createObjectURL(layout)}
                        title="Desk location"
                    ></iframe>
                )}
                {error && <Empty description="Layout not found"></Empty>}
            </Spinner>
        </StyledLayoutPage>
    );
};

export default LayoutPage;
