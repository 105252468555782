import React from 'react';

import PropTypes from 'prop-types';

import { marineBlue } from '../../../constants/generalConstants';

const FlightIcon = ({ size = 24, color = marineBlue, className }) => {
    return (
        <svg
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M18.5 16H1.49998C0.949977 16 0.499977 16.45 0.499977 17C0.499977 17.55 0.949977 18 1.49998 18H18.5C19.05 18 19.5 17.55 19.5 17C19.5 16.45 19.05 16 18.5 16ZM20.07 6.64C19.85 5.84 19.03 5.37 18.23 5.58L12.92 7L6.45998 0.980001C6.18998 0.720001 5.79998 0.630001 5.43998 0.730001C4.75998 0.920001 4.43998 1.7 4.78998 2.31L8.22998 8.27L3.25998 9.6L1.68998 8.36C1.43998 8.17 1.11998 8.1 0.809977 8.18L0.479977 8.27C0.159977 8.35 0.00997736 8.72 0.179977 9L2.05998 12.25C2.28998 12.64 2.74998 12.83 3.17998 12.72L19 8.48C19.8 8.26 20.28 7.44 20.07 6.64Z" />
        </svg>
    );
};

FlightIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
};

export default FlightIcon;
