import apiUrls from 'apiUrls';
import httpService from 'shared/services/httpService';

const getLocationsWithNotifications = async () => {
    const data = await httpService.get(
        apiUrls.GET_LOCATIONS_WITH_NOTIFICATIONS()
    );
    return data.data;
};

const getLocationWithNotifications = async locationId => {
    const data = await httpService.get(
        apiUrls.GET_LOCATION_WITH_NOTIFICATIONS(locationId)
    );
    return data.data;
};

const updateLocationNotifications = async (locationId, notifications) => {
    const data = await httpService.put(
        apiUrls.UPDATE_LOCATION_NOTIFICATIONS(locationId),
        notifications
    );
    return data.data;
};

export default {
    getLocationsWithNotifications,
    getLocationWithNotifications,
    updateLocationNotifications
};
