import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import StyledDisplayDate from './styled/DisplayDate.styled';

const DisplayDate = ({ date, className = '' }) => {
    const [dateClass, setDateClass] = useState('');

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        setDateClass(() => {
            const passed =
                date.getTime() < today.getTime() ? 'date-passed ' : '';
            return `date ${className} ${
                date.getTime() === today.getTime() ? 'date-today ' : passed
            }`;
        });
    }, [date]);

    return (
        <StyledDisplayDate className={dateClass}>
            <div>
                {date
                    .toLocaleString('default', { weekday: 'short' })
                    .toUpperCase()}
            </div>
            <div className="circle day-name">{date.getDate()}</div>
        </StyledDisplayDate>
    );
};

DisplayDate.propTypes = {
    date: PropTypes.instanceOf(Date),
    className: PropTypes.string
};

export default DisplayDate;
