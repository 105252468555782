import styled from 'styled-components';

import LoginPicture from '../../../shared/theme/assets/images/LoginPhoto.jpg';

const StyledLogin = styled.div`
    margin-top: -70px;
    background-image: url(${LoginPicture});
    background-size: cover;
    width: 100%;
    height: calc(100% + 70px);
    position: absolute;
    .login-layer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        .login-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 361px;
            height: 337px;
            background: var(--primaryWhite);
            border: 1px solid var(--darkNavy);
            box-sizing: border-box;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            padding: 40px 45px 46px;
            align-items: center;
            .header-section {
                font-style: normal;
                font-weight: bold;
                font-size: 50px;
                line-height: 63px;
                font-weight: 700;
                letter-spacing: 0em;
                text-align: center;
                margin-bottom: 6px;
            }
            .slogan-section {
                padding-top: 50px;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 25px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
        .login-error {
            padding-left: 13px;
            padding-right: 13px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: center;
            width: 271px;
        }
    }
`;

export default StyledLogin;
