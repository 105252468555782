import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { deleteProfileMember } from 'redux/slices/members';
import { CustomConfirmationModal } from 'shared/components/ConfirmationModal';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';

import LocationChip from './LocationChip';
import StyledUserWorkWeek from './styled/UserWorkWeek.styled';
import UserChip from './UserChip';
import UsersList from './UsersList';

const UserWorkWeek = React.forwardRef(
    (
        {
            className = '',
            isCurrentUser,
            week,
            person,
            profile,
            isGroupMember,
            membersRef
        },
        ref
    ) => {
        const [confirmationModalOpen, setConfirmationModalOpen] =
            useState(false);
        const dispatch = useDispatch();

        const toggleConfirmationModal = useCallback(() => {
            setConfirmationModalOpen(open => !open);
        }, []);

        const removeProfileMember = useCallback(() => {
            dispatch(deleteProfileMember(person.profileMemberId));
            setConfirmationModalOpen(open => !open);
        }, [person, dispatch]);

        const content = !person?.isGroupMember ? (
            <div ref={ref}>
                <StyledUserWorkWeek
                    className={`${className} ${
                        !isCurrentUser ? 'no-border' : ''
                    }`}
                >
                    <UserChip person={person} />
                    {week.map(day => (
                        <LocationChip
                            date={day}
                            key={day.getDay()}
                            editable={isCurrentUser}
                            profile={isCurrentUser ? profile : person.profile}
                        />
                    ))}
                    {!isGroupMember && !isCurrentUser && (
                        <div
                            onClick={toggleConfirmationModal}
                            className="removeBtn"
                        >
                            <CloseIcon />
                        </div>
                    )}
                    {!isCurrentUser && <hr />}
                    <CustomConfirmationModal
                        visible={confirmationModalOpen}
                        setVisible={toggleConfirmationModal}
                        handleConfirm={removeProfileMember}
                        title={'Remove Member'}
                    />
                </StyledUserWorkWeek>
            </div>
        ) : (
            <UsersList
                group={person}
                week={week}
                membersRef={membersRef}
                ref={el => (membersRef.current[person.id] = el)}
            />
        );

        return content;
    }
);

UserWorkWeek.propTypes = {
    className: PropTypes.string,
    isCurrentUser: PropTypes.bool,
    week: PropTypes.array,
    person: PropTypes.shape({
        id: PropTypes.string,
        profileMemberId: PropTypes.string,
        isGroupMember: PropTypes.bool,
        profile: PropTypes.object
    }),
    profile: PropTypes.object,
    isGroupMember: PropTypes.bool,
    membersRef: PropTypes.object
};

export default UserWorkWeek;
