import styled from 'styled-components';

const StyledUsersList = styled.div`
    transition: max-height 0.3s ease-out;
    overflow: hidden;
    height: auto;
    max-height: 55px;

    &.expanded {
        transition: max-height 0.3s ease-in;
        // eslint-disable-next-line prettier/prettier
        height: auto;
        max-height: 20000px;
    }

    .list-name {
        padding: 12px 3vw 16px;
        border-bottom: 1px solid var(--marine10);
        color: var(--marineBlue);
        font-weight: bold;
        font-size: 16px;
        display: flex;
    }

    .list-name .dropdown {
        fill: var(--orange);
        margin: 0 16px 0 8px;
        transition-duration: 0.2s;
    }

    .list {
        display: block;
    }

    .group-name {
        width: 85vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .rotate {
        transform: rotate(-90deg);
        transition-duration: 0.2s;
    }
    &.large .list-name {
        border-bottom: 0;
    }
    .list-name:hover {
        cursor: pointer;
        background-color: var(--cremeLight);
        color: var(--orange) !important;
    }
    .list-name:hover .list-name {
        color: var(--orange) !important;
    }
    .more {
        margin-left: auto;
        margin-right: 0;
    }
`;

export default StyledUsersList;
