import React from 'react';

import PropTypes from 'prop-types';

import { marineBlue } from '../../../constants/generalConstants';

const KeyboardArrowRight = ({ size = 24, color = marineBlue, className }) => {
    return (
        <svg
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.00001 15.875L12.88 11.995L9.00001 8.11498C8.81275 7.92814 8.70752 7.67449 8.70752 7.40998C8.70752 7.14546 8.81275 6.89181 9.00001 6.70498C9.39001 6.31498 10.02 6.31498 10.41 6.70498L15 11.295C15.39 11.685 15.39 12.315 15 12.705L10.41 17.295C10.02 17.685 9.39001 17.685 9.00001 17.295C8.62001 16.905 8.61001 16.265 9.00001 15.875Z"
                fill="#012840"
            />
        </svg>
    );
};

KeyboardArrowRight.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
};

export default KeyboardArrowRight;
