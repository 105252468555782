import styled from 'styled-components';

const StyledCustomInput = styled.div`
    .customInput.inputMargin {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .customInput .input-label,
    .customInput .label-disabled {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 2px;
    }
    .customInput .label-disabled {
        color: var(--darkGray);
    }
    .customInput input {
        font-weight: normal;
    }
    .customInput .ant-input-affix-wrapper {
        height: 40px;
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 10px;
    }
    .ant-input-affix-wrapper > input.ant-input {
        color: var(--black);
    }
    .customInput .ant-input-affix-wrapper .ant-input-prefix {
        left: 20px;
        margin-right: 6px;
    }
    .hasPrefix input {
        padding-left: 10px !important;
    }
    .anticon svg {
        fill: var(--secondaryBlue);
    }
    //when having error
    .error-input .ant-input-affix-wrapper {
        border-color: var(--red);
    }
    .error-input .ant-input-affix-wrapper-focused {
        -webkit-box-shadow: var(--shadowColorOnError);
        box-shadow: var(--shadowColorOnError);
    }
    .icon {
        position: absolute;
        z-index: 1;
        margin-left: 8px;
        opacity: 0.48;
    }
    .search {
        position: relative;
        display: flex;
        align-items: center;
        color: var(--fauxBlack);
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    .search input {
        padding: 10px 0px 10px 40px;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .search input::placeholder {
        color: var(--fauxBlack);
        opacity: 0.48;
        font-size: 14px;
        line-height: 20px;
    }
    .no-icon input {
        padding-left: 12px;
    }
`;

export default StyledCustomInput;
