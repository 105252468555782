import React, { useCallback, useState, useEffect } from 'react';

import { Table } from 'antd';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkFromHomeReport, reportsActions } from 'redux/slices/reports';
import { setClassName } from 'utils/componentPropHelpers';

import CustomMonthPicker from '../../../shared/components/CustomMonthPicker';
import DownloadButton from './DownloadButton';
import StyledWorkFromHomeReportTab from './styled/WorkFromHomeReportTab.styled';

const WorkFromHomeReportTab = () => {
    const [monthPickerValue, setMonthPickerValue] = useState();
    const reportData = useSelector(state => state.reports.workFromHomeReport);
    const isLoadingReport = useSelector(state => state.reports.isLoadingReport);
    const [fieldErrors, setFieldErrors] = useState({
        monthStartPeriodError: null
    });
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(reportsActions.clearReports());
        };
    }, []);

    const handlePickerStartChange = useCallback(val => {
        setMonthPickerValue(val);

        setFieldErrors(prev => ({
            ...prev,
            monthStartPeriodError: null
        }));
        dispatch(getWorkFromHomeReport(dayjs(val).format('YYYY-MM-DD')));
    }, []);

    const columns = [
        {
            title: 'First name',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
            key: 'emailAddress'
        },
        {
            title: 'Used',
            dataIndex: ['workFromHomeDays', 'used'],
            key: 'used'
        },
        {
            title: 'Total',
            dataIndex: ['workFromHomeDays', 'total'],
            key: 'total'
        }
    ];

    return (
        <StyledWorkFromHomeReportTab>
            <div className="selectors-section">
                <div className="selector">
                    <CustomMonthPicker
                        label="Month"
                        name="startDate"
                        value={monthPickerValue}
                        className={setClassName(
                            fieldErrors?.monthStartPeriodError
                        )}
                        onChange={handlePickerStartChange}
                        disabledDate={new Date()}
                        validateDateInFuture={false}
                    />
                </div>
                <DownloadButton
                    setFieldErrors={setFieldErrors}
                    selectedMonth={monthPickerValue}
                />
            </div>
            <Table
                dataSource={reportData}
                columns={columns}
                bordered
                loading={isLoadingReport}
                rowKey="emailAddress"
            ></Table>
        </StyledWorkFromHomeReportTab>
    );
};

export default WorkFromHomeReportTab;
