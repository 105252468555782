import httpService from 'shared/services/httpService';

import url from '../../apiUrls';

const getLayoutByDeskId = async deskId => {
    const response = await httpService.get(url.GET_LAYOUT_BY_DESKID(deskId));
    let contentDisposition = response.headers['content-disposition'];
    let match = contentDisposition?.match(/Office Layout - ([^.]*)/);
    return {
        label: match[0],
        file: response.data
    };
};

export default {
    getLayoutByDeskId
};
