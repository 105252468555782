import styled from 'styled-components';

const StyledButton = styled.div`
    .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 24px;
        box-sizing: content-box;
        border: 1px solid var(--marine10);
        border-radius: 4px;
        color: var(--marineBlue);
        font-weight: 600;
        font-size: 14px;
        cursor: pointer;
    }
    .ant-btn-primary {
        background-color: var(--red);
        color: var(--white);
        border: 0;
        text-align: center;
    }

    .ant-btn-select {
        background: #012840;
        color: var(--white);
        border: 0;
        text-align: center;
    }

    .ant-btn-primary:hover {
        background-color: var(--red);
        color: var(--white);
    }

    .ant-btn-default:hover {
        border-color: #d9d9d9;
        color: var(--marineBlue);
    }

    .btn-disabled,
    .btn-disabled:hover {
        border: 1px solid var(--gray) !important;
        svg {
            path {
                stroke: var(--darkGray) !important;
            }
        }
        background-color: var(--gray) !important;
        color: var(--darkGray) !important;
        cursor: not-allowed !important;
    }
`;

export default StyledButton;
