const { default: styled } = require('styled-components');

const StyledLocationNotificationsModal = styled.div`
    width: 100%;
    height: 65vh;
    & > * {
        padding: 0 32px;
    }
    .save-button {
        display: flex;
        justify-content: flex-end;
        padding: 16px 25px;
        background: rgba(230, 234, 236, 0.5);
        backdrop-filter: blur(10px);
    }

    .email-input {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e6eaec;
    }

    .emails {
        margin-top: 12px;
        margin-bottom: 16px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.4em;
        color: var(--fauxBlack);
        opacity: 0.5;
    }

    .single-member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
    }
    .email-error {
        color: var(--red);
    }
`;

export default StyledLocationNotificationsModal;
