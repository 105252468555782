import React from 'react';

import { Pie, G2 } from '@ant-design/plots';
import PropTypes from 'prop-types';
import { marine20, orange } from 'shared/constants/generalConstants';

const PieChart = ({
    data,
    width,
    height,
    innerRadius = 0.9,
    radius,
    statisticContent,
    statisticContentFontSize
}) => {
    const G = G2.getEngine('canvas');

    const cfg = {
        appendPadding: 20,
        data,
        angleField: 'count',
        colorField: 'status',
        radius: radius,
        legend: false,
        autoFit: false,
        width,
        height,
        innerRadius,
        label: {
            type: 'spider',
            labelHeight: 10,
            autoHide: true,
            formatter: (data, mappingData) => {
                const group = new G.Group({});
                group.addShape({
                    type: 'circle',
                    attrs: {
                        x: 0,
                        y: 0,
                        width: 40,
                        height: 50,
                        r: 5,
                        fill: mappingData.color
                    }
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 10,
                        y: 8,
                        text: `${data.status}`,
                        fill: mappingData.color
                    }
                });
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 0,
                        y: 20,
                        text: `${data.count} desks`,
                        fill: 'rgba(0, 0, 0, 0.65)',
                        fontWeight: 700
                    }
                });
                return group;
            }
        },
        color: ({ status }) => {
            if (status === 'Used') {
                return orange;
            }
            return marine20;
        },
        statistic: {
            title: false,
            content: {
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: statisticContentFontSize
                },
                content: statisticContent
            }
        }
    };

    return data && <Pie {...cfg} />;
};

PieChart.propTypes = {
    data: PropTypes.array,
    width: PropTypes.number,
    height: PropTypes.number,
    innerRadius: PropTypes.number,
    radius: PropTypes.number,
    statisticContent: PropTypes.string,
    statisticContentFontSize: PropTypes.number
};

export default PieChart;
