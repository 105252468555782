import React from 'react';

import { Helmet } from 'react-helmet-async';
import DashboardHeader from 'reports/components/DashboardHeader';
import { StyledDashboard } from 'shared/styles/StyledDashboard.styled';

import Main from '../../shared/components/layout/Main';
import officeTabs from './officeTabs';

const OfficeDashboard = () => {
    const pageTitle = 'CollaboMate';

    return (
        <StyledDashboard>
            <Helmet title={pageTitle} />
            <Main
                hasHeader={true}
                hasNavigation={true}
                hasHeading={false}
                hasCustomHeading={true}
                customHeadingComponent={<DashboardHeader tabs={officeTabs} />}
            />
        </StyledDashboard>
    );
};

export default OfficeDashboard;
