import React from 'react';

import AdminDashboard from 'admin/pages/AdminDashboard';
import LayoutPage from 'main/pages/LayoutPage';
import OfficeDashboard from 'office/pages/OfficeDashboard';
import { useRoutes } from 'react-router-dom';
import ReportsDashboard from 'reports/pages/ReportsDashboard';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';

import Login from './auth/pages/Login';
import AuthorizedNotFoundPage from './main/pages/AuthenticatedNotFoundPage';
import MainDashboard from './main/pages/MainDashboard';
import { CustomRoute } from './shared/components/CustomRoute';

const AllRoutes = () =>
    useRoutes([
        {
            path: STATIC_ROUTES_PATHS.login,
            element: <Login />
        },
        {
            path: STATIC_ROUTES_PATHS.main,
            element: (
                <CustomRoute
                    authenticated={true}
                    element={<MainDashboard />}
                    path={STATIC_ROUTES_PATHS.main}
                />
            )
        },
        {
            path: STATIC_ROUTES_PATHS.reportsDashboard,
            element: (
                <CustomRoute
                    authenticated={true}
                    element={<ReportsDashboard />}
                    path={STATIC_ROUTES_PATHS.reportsDashboard}
                />
            )
        },
        {
            path: STATIC_ROUTES_PATHS.layouts,
            element: (
                <CustomRoute
                    authenticated={true}
                    element={<LayoutPage />}
                    path={STATIC_ROUTES_PATHS.layouts}
                />
            )
        },
        {
            path: STATIC_ROUTES_PATHS.adminDashboard,
            element: (
                <CustomRoute
                    authenticated={true}
                    element={<AdminDashboard />}
                    path={STATIC_ROUTES_PATHS.adminDashboard}
                />
            )
        },
        {
            path: STATIC_ROUTES_PATHS.office,
            element: (
                <CustomRoute
                    authenticated={true}
                    element={<OfficeDashboard />}
                    path={STATIC_ROUTES_PATHS.office}
                />
            )
        },
        {
            path: STATIC_ROUTES_PATHS.unauthorized,
            element: (
                <AuthorizedNotFoundPage
                    message={"You don't have permission to view this page."}
                />
            )
        },
        {
            path: '*',
            element: <AuthorizedNotFoundPage />
        }
    ]);

export default AllRoutes;
