import styled from 'styled-components';

const StyledColourTag = styled.span`
    .ant-tag {
        font-size: 12px;
        border-radius: 20px;
        border: 0;
        margin: 0;
    }
`;

export default StyledColourTag;
