/* istanbul ignore file */
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION_ONE;
const BASE_API_URL_FULL = BASE_API_URL + API_VERSION;
const ME = '/Me';

export default {
    // Auth
    AUTH: BASE_API_URL_FULL + '/Auth',
    GOOGLE_USER_LOGIN: function () {
        return `${this.AUTH}/Google`;
    },
    MICROSOFT_USER_LOGIN: function () {
        return `${this.AUTH}/Microsoft`;
    },

    // Profiles
    PROFILES: BASE_API_URL_FULL + '/Profiles' + ME,
    CREATE_PROFILE_MEMBER: function () {
        return `${this.PROFILES}/Members`;
    },
    GET_PROFILE_MEMBERS: function () {
        return `${this.PROFILES}/Members`;
    },
    GET_MY_PROFILE: function () {
        return `${this.PROFILES}`;
    },
    DELETE_PROFILE_MEMBER: function (profileMemberId) {
        return `${this.PROFILES}/Members/${profileMemberId}`;
    },

    // Groups
    GROUPS: BASE_API_URL_FULL + '/Profiles' + ME + '/Groups',
    GET_GROUP_BY_ID: function (groupId) {
        return `${this.GROUPS}/${groupId}`;
    },
    GET_GROUPS: function () {
        return `${this.GROUPS}`;
    },
    CREATE_GROUP: function () {
        return `${this.GROUPS}`;
    },
    UPDATE_GROUP: function (groupId) {
        return `${this.GROUPS}/${groupId}`;
    },
    DELETE_GROUP: function (groupId) {
        return `${this.GROUPS}/${groupId}`;
    },

    // Members
    MEMBERS: BASE_API_URL_FULL + '/Members',
    SEARCH_PEOPLE: function (baseParams) {
        return `${this.MEMBERS}/Search/People/?${baseParams}`;
    },
    SEARCH_GROUPS: function (baseParams) {
        return `${this.MEMBERS}/Search/Groups/?${baseParams}`;
    },
    SEARCH_MEMBERS: function (baseParams) {
        return `${this.MEMBERS}/Search/?${baseParams}`;
    },

    //Work Locations
    WORKLOCATIONS: BASE_API_URL_FULL + '/Profiles' + ME + '/WorkLocations',
    CREATE_WORK_LOCATION: function () {
        return `${this.WORKLOCATIONS}`;
    },
    UPDATE_WORK_LOCATION: function (workLocationId) {
        return `${this.WORKLOCATIONS}/${workLocationId}`;
    },

    //Holidays
    HOLIDAYS: BASE_API_URL_FULL + '/Holidays',
    GET_HOLIDAYS: function () {
        return `${this.HOLIDAYS}`;
    },

    //Locations
    LOCATIONS: BASE_API_URL_FULL + '/Locations',
    GET_LOCATIONS: function (date) {
        return `${this.LOCATIONS}/${date}`;
    },
    GET_OFFICE_LOCATIONS: function () {
        return `${this.LOCATIONS}/Offices`;
    },

    //Reports
    REPORTS: BASE_API_URL_FULL + '/Reports',
    WorkFromHome_CSV_Report: function (date) {
        return `${this.REPORTS}/WorkFromHome/${date}/Csv`;
    },
    WorkFromHome_Report: function (date) {
        return `${this.REPORTS}/WorkFromHome/${date}`;
    },
    DESKS_SUMMARY: function (date, locationId) {
        return `${this.REPORTS}/Locations/${locationId}/desks/summary/${date}`;
    },

    //Layouts
    LAYOUTS: BASE_API_URL_FULL + '/Layouts',
    GET_LAYOUT_BY_DESKID: function (deskId) {
        return `${this.LAYOUTS}/${deskId}`;
    },

    //Location Notifications
    LOCATION_NOTIFICATIONS: BASE_API_URL_FULL + '/Locations',
    GET_LOCATIONS_WITH_NOTIFICATIONS: function () {
        return `${this.LOCATION_NOTIFICATIONS}/Notifications`;
    },
    GET_LOCATION_WITH_NOTIFICATIONS: function (locationId) {
        return `${this.LOCATION_NOTIFICATIONS}/${locationId}/Notifications`;
    },
    UPDATE_LOCATION_NOTIFICATIONS: function (locationId) {
        return `${this.LOCATION_NOTIFICATIONS}/${locationId}/Notifications`;
    },

    //Users
    USERS: BASE_API_URL_FULL + '/Users',
    GET_ALL_USERS: function (baseParams) {
        return `${this.USERS}/Roles?${baseParams}`;
    },
    GET_USER_BY_ID: function (userId) {
        return `${this.USERS}/${userId}`;
    },
    UPDATE_USER_ROLES: function (userId) {
        return `${this.USERS}/${userId}/Roles`;
    },

    //Roles
    ROLES: BASE_API_URL_FULL + '/Roles',
    GET_ALL_ROLES: function () {
        return `${this.ROLES}`;
    }
};
