import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import usersService from 'admin/services/usersService';

const initialState = {
    users: [],
    currentPaging: {
        skip: 0,
        pageSize: 20
    },
    modalUser: {},
    isLoading: false
};

export const getAllUsers = createAsyncThunk(
    'users/getAllUsers',
    async queryParams => {
        const response = await usersService.getAllUsers(queryParams);
        return response.data;
    }
);

export const getUserById = createAsyncThunk(
    'users/getUserById',
    async userId => {
        const response = await usersService.getUserById(userId);
        return response;
    }
);

export const updateUserRoles = createAsyncThunk(
    'users/updateUserRoles',
    async props => {
        const response = await usersService.updateUserRoles(
            props.userId,
            props.roles
        );
        return response;
    }
);

const { reducer: usersReducer, actions } = createSlice({
    name: 'users',
    initialState,
    reducers: {
        clearUsers(state) {
            state.users = initialState.users;
        }
    },
    extraReducers: builder => {
        builder.addCase(getAllUsers.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAllUsers.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(getAllUsers.fulfilled, (state, action) => {
            state.users = [...state.users, ...action.payload.data];
            state.currentPaging = action.payload.metadata;
            state.isLoading = false;
        });
        builder.addCase(getUserById.fulfilled, (state, action) => {
            state.modalUser = action.payload.data;
        });
        builder.addCase(updateUserRoles.fulfilled, (state, action) => {
            let user = state.users.find(x => x.id == action.payload.data.id);
            user.roles = action.payload.data.roles;
        });
    }
});

export { usersReducer, actions as usersActions };
