import WorkFromHomeReportTab from './components/WorkFromHomeReportTab/WorkFromHomeReportTab';

const reportTabs = [
    {
        label: 'Work from home',
        value: 1,
        disabled: false,
        child: props => <WorkFromHomeReportTab></WorkFromHomeReportTab>
    }
];

export default reportTabs;
