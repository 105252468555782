import React from 'react';

import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';
import { canNavigate } from 'utils/navigationHelper';

import { NavigationItem } from './NavigationItem';
import { StyledNavigation } from './styled/Navigation.styled';

const NAV_ITEMS = [
    { route: STATIC_ROUTES_PATHS.main, label: 'Home' },
    { route: STATIC_ROUTES_PATHS.office, label: 'Office Stats' },
    { route: STATIC_ROUTES_PATHS.reportsDashboard, label: 'Reports' },
    { route: STATIC_ROUTES_PATHS.adminDashboard, label: 'Admin' }
];

const Navigation = () => {
    const path = window.location.pathname;

    const navOptions = NAV_ITEMS.map(x =>
        canNavigate(x.route) ? (
            <NavigationItem
                key={x.label}
                route={x.route}
                label={x.label}
                active={path.startsWith(x.route)}
            ></NavigationItem>
        ) : null
    );

    return (
        <StyledNavigation>
            <div className="navigation-wrapper">
                <div className="navigation-inner">{navOptions}</div>
            </div>
        </StyledNavigation>
    );
};

export default Navigation;
