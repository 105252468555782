import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { groupsActions } from 'redux/slices/groups';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';

import UserChip from '../../components/UserChip';

const Member = ({ member }) => {
    const dispatch = useDispatch();

    return (
        <div className="single-member">
            <UserChip person={member} showEmail />
            <div
                className="remove-button"
                onClick={() =>
                    dispatch(groupsActions.removeModalMember(member.id))
                }
            >
                <CloseIcon />
            </div>
        </div>
    );
};

Member.propTypes = {
    member: PropTypes.shape({
        id: PropTypes.string
    })
};

export default Member;
