import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import { StyledCustomTabs } from './styled/CustomTabs.styled';

const { TabPane } = Tabs;

const CustomTabs = ({ tabs }) => {
    return (
        <StyledCustomTabs>
            <div className="tabs-container">
                <Tabs
                    tabPosition="top"
                    animated={{ inkBar: false, tabPane: false }}
                >
                    {tabs.map(tab => (
                        <TabPane
                            key={tab.title}
                            tab={tab.title}
                            disabled={tab.disabled}
                        >
                            <tab.Component />
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </StyledCustomTabs>
    );
};

CustomTabs.propTypes = {
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            disabled: PropTypes.bool
        })
    )
};
