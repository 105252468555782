import React, { useState, useCallback } from 'react';

import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { membersActions, searchMembers } from 'redux/slices/members';
import CustomAutoComplete from 'shared/components/AutoComplete';
import SearchIcon from 'shared/theme/assets/icons/SearchIcon';
import UserChip from 'wfh/components/UserChip';

const UserSearch = props => {
    const [peopleSearchPhrase, setPeopleSearchPhrase] = useState('');
    const searchResult = useSelector(state => state.members.searchResult);
    const dispatch = useDispatch();

    const debouncedSearch = useCallback(
        debounce(value => dispatch(searchMembers(value)), 500),
        []
    );

    const search = useCallback(
        event => {
            setPeopleSearchPhrase(event.target.value);
            debouncedSearch(event.target.value);
        },
        [setPeopleSearchPhrase, debouncedSearch]
    );

    const options = searchResult.map(member => (
        <div
            onClick={() => {
                props.addMember(member);
                setPeopleSearchPhrase('');
                dispatch(membersActions.clearSearchResults());
            }}
            key={member.id}
        >
            <UserChip person={member} showEmail />
        </div>
    ));

    return (
        <CustomAutoComplete
            placeholder={props.placeholder}
            iconComponent={props.showIcon ? <SearchIcon /> : null}
            options={options}
            onChange={search}
            value={peopleSearchPhrase}
        />
    );
};

UserSearch.propTypes = {
    addMember: PropTypes.func,
    placeholder: PropTypes.string,
    showIcon: PropTypes.bool
};

export default UserSearch;
