import styled from 'styled-components';

const StyledUserWorkWeek = styled.div`
    padding: 8px 3vw;
    display: grid;
    column-gap: 7px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 0.1fr;
    grid-template-rows: auto;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--marine10);

    &.no-border {
        border: 0;
        padding-bottom: 0;
    }
    hr {
        margin-top: 8px;
        width: calc(100% - 48px);
        height: 0;
        justify-self: end;
        border-top: 1px solid var(--marine10);
        grid-column: 1 / span 6;
    }

    .removeBtn {
        cursor: pointer;
    }
`;

export default StyledUserWorkWeek;
