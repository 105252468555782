import React, { useCallback } from 'react';

import { Tag } from 'antd';
import PropTypes from 'prop-types';

import StyledColourTag from './styled/ColourTag.styled';

const ColourTag = ({ selection, color, text, closable, handleOnClose }) => {
    const onClose = useCallback(() => {
        handleOnClose(selection);
    }, [selection, handleOnClose]);

    return (
        <StyledColourTag>
            <Tag color={color} closable={closable} onClose={onClose}>
                {text}
            </Tag>
        </StyledColourTag>
    );
};

ColourTag.propTypes = {
    selection: PropTypes.string,
    color: PropTypes.string,
    text: PropTypes.string,
    closable: PropTypes.bool,
    handleOnClose: PropTypes.func
};

export default ColourTag;
