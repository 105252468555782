import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import rolesService from 'admin/services/rolesService';

const initialState = {
    roles: []
};

export const getAllRoles = createAsyncThunk('roles/getAllRoles', async () => {
    const response = await rolesService.getAllRoles();
    return response;
});

const { reducer: rolesReducer, actions } = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getAllRoles.fulfilled, (state, action) => {
            state.roles = action.payload.data;
        });
    }
});

export { rolesReducer, actions as rolesActions };
