const { default: styled } = require('styled-components');

const StyledDeskChip = styled.div`
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    justify-content: space-between;

    .desk-info {
        display: block;
        margin-left: 10px;
    }

    .desk-name-label {
        padding: 0;
        border: 0;
        color: var(--fauxBlack);
    }

    .select-button {
        margin-left: auto;
        margin-right: 5px;
    }

    .circle {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        color: var(--marine80);
        font-weight: bold;
        cursor: pointer;
        font-size: 12px;
    }

    .ant-badge-count {
        margin-left: 10px;
        color: var(--orange);
        line-height: 17px;
        border: 1px solid var(--orange);
        background-color: var(--white);
    }
`;

export default StyledDeskChip;
