import { CloseCircleTwoTone } from '@ant-design/icons';
import { notification } from 'antd';

export const successNotification = (message, duration) =>
    notification.success({ message, duration });

export const errorNotification = (message, duration, placement = 'topRight') =>
    notification.error({
        message,
        duration,
        icon: <CloseCircleTwoTone twoToneColor="#d93636" />,
        placement: placement
    });

export const warningNotification = (message, duration) =>
    notification.warning({ message, duration });

export const infoNotification = (message, duration) =>
    notification.info({ message, duration });
