import styled from 'styled-components';

const StyledNavigationItem = styled.div`
    .nav-item > .label > a {
        font-size: 16px;
        color: var(--black);
        text-decoration: none;
    }
    .nav-item {
        width: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 12.5px 30px;
    }
    .nav-item:hover {
    }
    .nav-item:hover .label {
        color: var(--primary) !important;
        padding-bottom: 0px;
    }
    .nav-item-active {
        background: var(--lighterOrange);
    }
    .nav-item-active .label {
        font-weight: bold;
        font-size: 16px;
        text-align: center;
        color: var(--orange);
        padding-bottom: 0px;
    }
    .nav-item-active > .label > a {
        color: var(--orange);
    }
`;

export { StyledNavigationItem };
