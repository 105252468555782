import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StyledNavigationItem } from './styled/NavigationItem.styled';

export const NavigationItem = ({ route, label, active }) => (
    <StyledNavigationItem>
        <div className={active ? 'nav-item nav-item-active' : 'nav-item'}>
            <div className="label">
                <Link to={route}>{label}</Link>
            </div>
        </div>
    </StyledNavigationItem>
);

NavigationItem.propTypes = {
    route: PropTypes.string,
    label: PropTypes.string,
    active: PropTypes.bool
};
