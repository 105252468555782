import React, { useCallback } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';

export const CustomModal = ({
    visible,
    setVisible,
    title,
    children,
    closable = true,
    centered = true,
    destroyOnClose = true,
    enableClose = true,
    maxWidth = false,
    footer,
    className,
    dataTestId,
    closeIcon,
    handleCustomCancel = () => {} //be aware of specific cases it is used
}) => {
    const onCancel = useCallback(() => {
        if (enableClose) {
            setVisible(false);
            handleCustomCancel();
        }
    }, [enableClose, setVisible, handleCustomCancel]);

    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            closeIcon={closeIcon}
            closable={closable}
            destroyOnClose={destroyOnClose}
            footer={footer}
            title={title}
            className={className}
            centered={centered}
            data-testid={dataTestId}
        >
            <div className={`modal-content${maxWidth ? ' maxWidth' : ' '}`}>
                {children}
            </div>
        </Modal>
    );
};

CustomModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    title: PropTypes.node,
    children: PropTypes.node,
    closable: PropTypes.bool,
    centered: PropTypes.bool,
    destroyOnClose: PropTypes.bool,
    enableClose: PropTypes.bool,
    maxWidth: PropTypes.bool,
    footer: PropTypes.node,
    className: PropTypes.string,
    dataTestId: PropTypes.string,
    closeIcon: PropTypes.node,
    handleCustomCancel: PropTypes.func
};
