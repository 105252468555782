import React, { useCallback, useState } from 'react';

import { Dropdown } from 'antd';
import PropTypes from 'prop-types';

import CustomInput from './Input';
import StyledCustomAutoComplete from './styled/AutoComplete.styled';

const CustomAutoComplete = ({ className, options = [], onSelect, ...rest }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const onFocus = useCallback(event => {
        event.target.select();
        setDropdownVisible(true);
    }, []);

    const hideDropdown = () => {
        setDropdownVisible(false);
    };
    const onBlur = useCallback(() => {
        setTimeout(() => {
            hideDropdown();
        }, 200);
    }, []);

    const items = options.map(option => ({
        key: option.key,
        label: (
            <div className="option" onClick={hideDropdown} key={option.key}>
                {option}
            </div>
        )
    }));

    return (
        <StyledCustomAutoComplete className={className}>
            <>
                <Dropdown
                    menu={{ items }}
                    open={dropdownVisible && options.length > 0}
                    destroyPopupOnHide={true}
                >
                    <CustomInput {...rest} onFocus={onFocus} onBlur={onBlur} />
                </Dropdown>
            </>
        </StyledCustomAutoComplete>
    );
};

CustomAutoComplete.propTypes = {
    className: PropTypes.string,
    options: PropTypes.array,
    onSelect: PropTypes.func,
    rest: PropTypes.object
};

export default CustomAutoComplete;
