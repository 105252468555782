import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    absenceWorkLocationId,
    contractorMentorMaterId,
    flexibleRemoteMentorMaterId,
    officeWorkLocationId,
    homeWorkLocationId,
    remoteWorkLocationId,
    unspecifiedWorkLocationId
} from 'shared/constants/generalConstants';
import FlightIcon from 'shared/theme/assets/icons/FlightIcon';

import ArrowDropDownIcon from '../../shared/theme/assets/icons/ArrowDropDownIcon';
import HomeIcon from '../../shared/theme/assets/icons/HomeIcon';
import OfficeIcon from '../../shared/theme/assets/icons/OfficeIcon';
import UnspecifiedLocationIcon from '../../shared/theme/assets/icons/UnspecifiedLocationIcon';
import profilesService from '../services/profilesService';
import LocationPicker from './LocationPicker';
import StyledLocationChip from './styled/LocationChip.styled';

const LocationChip = ({ date, editable, className = '', profile }) => {
    const [passed, setPassed] = useState(false);
    const [locationClass, setLocationClass] = useState('');
    const holidays = useSelector(state => state.holidays.holidays);
    const [isHoliday, setIsHoliday] = useState(false);

    const workLocations = profile ? profile.workLocations : [];

    const holiday = holidays.find(
        x =>
            dayjs(x.date).format('YYYY-MM-DD') ==
                dayjs(date).format('YYYY-MM-DD') &&
            (x.regionId == null || x.regionId == profile?.location?.regionId)
    );

    let workLocationAccordingToDate = workLocations.filter(
        wl =>
            dayjs(wl.startDate).format('YYYY-MM-DD') <=
                dayjs(date).format('YYYY-MM-DD') &&
            dayjs(wl.endDate).format('YYYY-MM-DD') >=
                dayjs(date).format('YYYY-MM-DD')
    );

    // Filter work locations to acquire the appropriate for the date /default, selected by user or company holiday/
    if (workLocationAccordingToDate.length > 1) {
        const defaultWorkLocations = workLocationAccordingToDate.filter(
            wl => wl.isDefinedByUser === false
        );
        //If we have holiday that day, default work locations number will be 2 because both are not defined by user
        //We would like to show the holiday - holiday starts and ends on the same date
        if (defaultWorkLocations.length > 1) {
            workLocationAccordingToDate = workLocationAccordingToDate.filter(
                wl =>
                    dayjs(wl.startDate).format('YYYY-MM-DD') ===
                    dayjs(wl.endDate).format('YYYY-MM-DD')
            );
        } else {
            //If we have work location defined by the user we will filter it by that property
            workLocationAccordingToDate = workLocationAccordingToDate.filter(
                wl => wl.isDefinedByUser === true
            );
        }
    }

    //Title on location chip
    const title = isHoliday
        ? holiday?.name
        : workLocationAccordingToDate[0]?.title ?? 'Unspecified';

    const workLocationTypeId =
        workLocationAccordingToDate[0]?.workLocationTypeId ??
        unspecifiedWorkLocationId;

    const isDefinedByUser =
        workLocationAccordingToDate[0]?.isDefinedByUser ?? false;

    const mentorMaterId = profilesService.getMentomaterId(profile, date);

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const isPassed = date.getTime() < today.getTime();

        setPassed(() => {
            return isPassed;
        });

        setLocationClass(() => {
            return `location-chip ${className} ${
                isPassed ? 'date-passed ' : ''
            }`;
        });

        const isHolidayHomeOffice =
            holiday != null &&
            mentorMaterId != flexibleRemoteMentorMaterId &&
            mentorMaterId != contractorMentorMaterId &&
            workLocationTypeId != unspecifiedWorkLocationId;

        setIsHoliday(isHolidayHomeOffice);

        if (holiday != null) {
            if (editable) {
                setLocationClass(() => {
                    return `location-chip ${className} ${
                        isHolidayHomeOffice || isPassed ? 'date-passed ' : ''
                    }`;
                });
            }
        }
    }, [date]);

    const isOfficeWorkLocationIcon =
        workLocationTypeId === officeWorkLocationId && !isHoliday;

    const isHomeWorkLocationIcon =
        (workLocationTypeId === homeWorkLocationId && !isHoliday) ||
        workLocationTypeId === remoteWorkLocationId;

    const isAbsentOrCompanyHolidayIcon =
        workLocationTypeId === absenceWorkLocationId || isHoliday;

    const isUnspecifiedWorkLocationIcon =
        workLocationTypeId === unspecifiedWorkLocationId;

    return (
        <LocationPicker
            date={dayjs(date).format('YYYY-MM-DD')}
            workLocation={workLocationAccordingToDate}
            workLocationTypeId={workLocationTypeId}
            isDefinedByUser={isDefinedByUser}
            passed={passed}
            editable={editable}
            holiday={holiday}
        >
            <StyledLocationChip className={locationClass} editable={editable}>
                {isOfficeWorkLocationIcon && (
                    <OfficeIcon className="location-icon" />
                )}
                {isHomeWorkLocationIcon && (
                    <HomeIcon className="location-icon" />
                )}
                {isAbsentOrCompanyHolidayIcon && (
                    <FlightIcon className="location-icon" />
                )}
                {isUnspecifiedWorkLocationIcon && (
                    <UnspecifiedLocationIcon className="location-icon" />
                )}
                <div>
                    <div className="work-from">{title}</div>
                    <div className="location">
                        {isHoliday
                            ? 'Company Holiday'
                            : workLocationAccordingToDate[0]?.location ?? '-'}
                    </div>
                </div>
                {editable && <ArrowDropDownIcon className="dropdown" />}
            </StyledLocationChip>
        </LocationPicker>
    );
};

LocationChip.propTypes = {
    date: PropTypes.instanceOf(Date),
    editable: PropTypes.bool,
    className: PropTypes.string,
    profile: PropTypes.shape({
        location: PropTypes.object,
        workLocations: PropTypes.array
    })
};

export default LocationChip;
