import React, {
    useEffect,
    useState,
    useRef,
    createRef,
    useCallback
} from 'react';

import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { datePickerActions } from 'redux/slices/datePicker';
import { getProfileGroups } from 'redux/slices/groups';
import { getHolidays } from 'redux/slices/holidays';
import { createProfileMember, getProfileMembers } from 'redux/slices/members';
import { showNavigation } from 'utils/navigationHelper';
import UserSearch from 'wfh/components/UserSearch';

import authService from '../../auth/services/authService';
import CustomButton from '../../shared/components/Button';
import Main from '../../shared/components/layout/Main';
import Spinner from '../../shared/components/Spinner';
import AddIcon from '../../shared/theme/assets/icons/AddIcon';
import DisplayDate from '../../wfh/components/DisplayDate';
import UsersList from '../../wfh/components/UsersList';
import UserWorkWeek from '../../wfh/components/UserWorkWeek';
import WeekPicker from '../../wfh/components/WeekPicker';
import UserGroupModal from '../../wfh/modals/Groups/UserGroupModal';
import StyledMainDashboard from './styled/MainDashboard.styled';

const MainDashboard = () => {
    const pageTitle = 'CollaboMate';
    const [groupModalOpen, setGroupModalOpen] = useState(false);
    const { profileGroups, isLoadingGroups } = useSelector(
        state => state.groups
    );
    const { profileMembers, isLoadingMembers } = useSelector(
        state => state.members
    );
    const week = useSelector(state =>
        state.datePicker.displayedWeek.map(day => {
            return new Date(day);
        })
    );
    const dispatch = useDispatch();
    const groupsRef = useRef([]);
    const membersRef = useRef([]);
    const currentProfileRef = useRef([]);
    const { currentProfile, isLoading } = useSelector(state => state.profiles);

    useEffect(() => {
        dispatch(datePickerActions.currentWeek());
        dispatch(getProfileMembers());
        dispatch(getProfileGroups());
        dispatch(getHolidays());
    }, []);

    const toggleAddGroup = useCallback(() => {
        setGroupModalOpen(() => !groupModalOpen);
    }, [groupModalOpen]);

    const currentProfileDirectoryId = authService.getProfileDirectoryId();

    const findParentMember = (membersList, memberId, parent = null) => {
        for (let m of membersList) {
            let res =
                m.id == memberId
                    ? parent
                    : m.members && findParentMember(m.members, memberId, m);
            if (res) {
                return res;
            }
        }
    };

    const findParentGroup = memberId => {
        let groupParent = profileGroups.find(g =>
            g.members.find(m => m.id == memberId)
        );
        for (let group of profileGroups) {
            let parentMember = findParentMember(group.members, memberId);
            if (parentMember) {
                clickGroupElem(group.members, parentMember);
                groupParent = group;
            }
        }
        return groupParent;
    };

    const highlightMember = (member, parentGroup, parentMember) => {
        const memberElem = membersRef.current[member.id];
        const isSingleAdded = profileMembers.some(
            m => m.id == member.id && !m.isGroupMember
        );

        if (isSingleAdded) {
            setHighlight(memberElem);
        } else if (member.id === currentProfileDirectoryId) {
            setHighlight(currentProfileRef.current);
        } else if (parentMember) {
            clickGroupElem(profileMembers, parentMember);
            setHighlight(memberElem);
        } else if (parentGroup) {
            const groupElem = groupsRef.current[parentGroup.id];
            if (!groupElem.parentNode.classList.contains('expanded')) {
                groupElem.click();
            }
            setHighlight(memberElem);
        } else if (member.isGroupMember) {
            setHighlight(memberElem);
        }
    };

    const setHighlight = memberElem => {
        if (memberElem) {
            memberElem.classList.remove('alerts-border');
            setTimeout(() => {
                memberElem.scrollIntoView(false);
                memberElem.classList.add('alerts-border');
            }, 500);
        }
    };

    const addMember = useCallback(
        member => {
            const parentGroup = findParentGroup(member.id);
            const parentMember = findParentMember(profileMembers, member.id);

            if (
                !parentGroup &&
                !parentMember &&
                member.id !== currentProfileDirectoryId &&
                !profileMembers.some(m => m.id == member.id)
            ) {
                dispatch(createProfileMember(member));
            } else {
                highlightMember(member, parentGroup, parentMember);
            }
        },
        [
            currentProfileDirectoryId,
            dispatch,
            findParentGroup,
            findParentMember,
            highlightMember,
            profileMembers
        ]
    );

    const clickGroupElem = (membersList, parentMember) => {
        if (parentMember) {
            const groupElem = membersRef.current[parentMember.id];
            if (!groupElem.parentNode.classList.contains('expanded')) {
                groupElem.click();
            }

            if (parentMember.parentId) {
                clickGroupElem(
                    membersList,
                    findParentMember(membersList, parentMember.id)
                );
            }
        }
    };

    groupsRef.current = profileGroups.map(
        (element, i) => groupsRef.current[i] ?? createRef()
    );
    membersRef.current = profileMembers.map(
        (element, i) => membersRef.current[i] ?? createRef()
    );

    const onClickCurrentWeek = useCallback(() => {
        dispatch(datePickerActions.currentWeek());
    }, [dispatch]);

    return (
        <StyledMainDashboard>
            <Helmet title={pageTitle} />
            <Main
                hasHeader={true}
                hasNavigation={showNavigation()}
                hasHeading={false}
                hasCustomHeading={false}
                hasFooter={false}
            >
                <Spinner
                    spinning={isLoading || isLoadingGroups || isLoadingMembers}
                >
                    <div className="background">
                        <div className="top-section">
                            <CustomButton onClick={onClickCurrentWeek}>
                                Today
                            </CustomButton>
                            <WeekPicker />
                            <CustomButton
                                type="primary"
                                onClick={toggleAddGroup}
                                icon={<AddIcon />}
                            >
                                Add Group
                            </CustomButton>
                        </div>

                        <div className="dates-section">
                            <div className="date self">
                                <UserSearch
                                    placeholder="Search for people"
                                    addMember={addMember}
                                    showIcon
                                />
                            </div>
                            {week.map(day => (
                                <DisplayDate date={day} key={day.getDay()} />
                            ))}
                        </div>
                    </div>

                    <UserWorkWeek
                        week={week}
                        isCurrentUser
                        profile={currentProfile}
                        ref={currentProfileRef}
                    />

                    {profileMembers.map(member => {
                        return (
                            <UserWorkWeek
                                week={week}
                                key={member.id}
                                person={member}
                                ref={el => {
                                    if (!membersRef.current[member.id]) {
                                        membersRef.current[member.id] = el;
                                    }
                                }}
                                membersRef={membersRef}
                            />
                        );
                    })}

                    {profileGroups.map(group => {
                        return (
                            <UsersList
                                group={group}
                                week={week}
                                key={group.id}
                                ref={el => (groupsRef.current[group.id] = el)}
                                membersRef={membersRef}
                            />
                        );
                    })}

                    <UserGroupModal
                        visible={groupModalOpen}
                        closeModal={toggleAddGroup}
                    />
                </Spinner>
            </Main>
        </StyledMainDashboard>
    );
};

export default MainDashboard;
