import styled from 'styled-components';

const StyledDownloadButton = styled.div`
    padding-top: 26px;
    .btn-normal {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        width: 150px;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: var(--marineBlue);
        height: 40px;
        border: 1px solid var(--marine10);
        border-radius: 4px;
        cursor: pointer;
        svg {
            margin-right: 8px;
            path {
                stroke: var(--marineBlue);
            }
        }
        .ant-spin.ant-spin-sm.ant-spin-spinning {
            margin-bottom: -3px;
            margin-right: 8px;
        }
    }
    .btn-normal:hover {
        color: var(--orange);
    }
    #download-hidden {
        display: none;
    }
`;

export default StyledDownloadButton;
