import apiUrls from 'apiUrls';
import httpService from 'shared/services/httpService';

const getAllUsers = async queryParams => {
    const skip = queryParams.skip;
    const take = queryParams.pageSize;
    let baseParams = `skip=${skip}&take=${take}`;

    //if there is given search string
    if (queryParams.searchPhrase) {
        baseParams += `&query=${queryParams.searchPhrase}`;
    }

    queryParams.selectedRoles?.map(x => {
        baseParams += `&filterRoleIds=${x}`;
    });

    //if there is given search string
    if (queryParams.sortBy) {
        baseParams += `&sortBy=${queryParams.sortBy}`;
    }

    //if there is given search string
    if (queryParams.sortAsc) {
        baseParams += `&sortAsc=${queryParams.sortAsc}`;
    }

    const data = await httpService.get(apiUrls.GET_ALL_USERS(baseParams));
    return data;
};

const getUserById = async userId => {
    const data = await httpService.get(apiUrls.GET_USER_BY_ID(userId));
    return data.data;
};

const updateUserRoles = async (userId, roles) => {
    const data = await httpService.put(
        apiUrls.UPDATE_USER_ROLES(userId),
        roles
    );
    return data.data;
};

export default {
    getAllUsers,
    getUserById,
    updateUserRoles
};
