import { useSelector } from 'react-redux';

import Member from './Member';

const GroupMembers = () => {
    const members = useSelector(state => state.groups.modalGroup.members);

    return (
        <div className="members-list">
            {members?.map(member => (
                <Member key={member.id} member={member} />
            ))}
        </div>
    );
};

export default GroupMembers;
