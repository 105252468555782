import styled from 'styled-components';

const StyledReportsDashboard = styled.div`
    width: 100%;
    position: absolute;
    .main {
        max-width: inherit !important;
        padding: 14px 90px !important;
    }
    .main .ant-spin.ant-spin-lg.ant-spin-spinning {
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }
    @media screen and (max-width: 1024px) {
        .main {
            max-width: inherit !important;
            padding: 14px 50px !important;
        }
    }
    @media screen and (max-width: 768px) {
        .main {
            max-width: inherit !important;
            padding: 14px 50px !important;
        }
        .people-dashboard-header-wrapper {
            padding: 24px 30px 0px 35px;
        }
    }
`;

export { StyledReportsDashboard };
