const { default: styled } = require('styled-components');

const StyledOfficeLocationModal = styled.div`
    width: 100%;
    height: 65vh;

    & > * {
        padding: 0 32px;
    }

    .location-floors {
        display: flex;
    }
    .save-button {
        display: flex;
        justify-content: flex-end;
        padding: 16px 25px;
        background: rgba(230, 234, 236, 0.5);
        backdrop-filter: blur(10px);
    }

    .location-select {
        margin-top: 12px;
        margin-bottom: 14px;
    }
    .location-desks {
        height: calc(65vh - 210px);
        overflow: scroll;
        ::-webkit-scrollbar {
            width: 5px;
        }
    }
    .location-desks :last-child {
        margin-bottom: 0;
    }
    .desks {
        margin-top: 12px;
        margin-bottom: 16px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.4em;
        color: var(--fauxBlack);
        opacity: 0.5;
    }
    .no-desks {
        margin: 20px 5px;
        font-size: 14px;
        text-align: center;
    }
`;

export default StyledOfficeLocationModal;
