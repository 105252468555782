import React, { useCallback } from 'react';

import PropTypes from 'prop-types';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';

import CustomButton from './Button';
import { StyledCustomConfirmationModal } from './styled/ConfirmationModal.styled';

export const CustomConfirmationModal = ({
    visible,
    setVisible,
    title,
    contentText,
    handleConfirm,
    className,
    funcParams,
    dataTestId
}) => {
    const onCancel = useCallback(() => {
        setVisible(false);
    }, [setVisible]);
    const onConfirm = useCallback(() => {
        handleConfirm(funcParams);
    }, [funcParams, handleConfirm]);

    return (
        <StyledCustomConfirmationModal
            visible={visible}
            setVisible={setVisible}
            title={[
                <div
                    data-testid={dataTestId}
                    className="custom-modal-header"
                    key="confirmation-modal-title"
                >
                    {title}
                </div>
            ]}
            closeIcon={<CloseIcon />}
            footer={[
                <div
                    className="custom-modal-footer"
                    key="confirmation-modal-footer"
                >
                    <div className="button-section">
                        <CustomButton
                            text="Cancel"
                            type="normal footer-button"
                            disabled={false}
                            onClick={onCancel}
                        >
                            Cancel
                        </CustomButton>
                    </div>
                    <div className="button-section">
                        <CustomButton
                            type="primary"
                            disabled={false}
                            onClick={onConfirm}
                        >
                            Confirm
                        </CustomButton>
                    </div>
                </div>
            ]}
            className={className}
        >
            <div className="secondary-title">Are you sure?</div>
            <div className="inner-modal-content">{contentText}</div>
        </StyledCustomConfirmationModal>
    );
};

CustomConfirmationModal.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    title: PropTypes.string,
    contentText: PropTypes.string,
    handleConfirm: PropTypes.func,
    className: PropTypes.string,
    funcParams: PropTypes.object,
    dataTestId: PropTypes.string
};
