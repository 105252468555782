import dayjs from 'dayjs';
import {
    absenceWorkLocationId,
    flexibleRemoteMentorMaterId,
    homeMentorMaterId,
    homeWorkLocationId,
    officeHomeMentorMaterId,
    officeWorkLocationId,
    remoteWorkLocationId
} from 'shared/constants/generalConstants';

import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getMyProfile = async () => {
    const data = await httpService.get(url.GET_MY_PROFILE());
    return data.data;
};

const getMentomaterId = (profile, date) => {
    date = dayjs(date).format('YYYY-MM-DD');

    let workLocation = profile?.workLocations?.find(
        x =>
            !x.isDefinedByUser &&
            dayjs(x.startDate).format('YYYY-MM-DD') <= date &&
            dayjs(x.endDate).format('YYYY-MM-DD') >= date
    );

    return (
        toMentomater(workLocation?.workLocationTypeId) ?? profile?.mentormaterId
    );
};

const toMentomater = workLocationTypeId => {
    switch (workLocationTypeId) {
        case officeWorkLocationId:
            return officeHomeMentorMaterId;
        case homeWorkLocationId:
            return homeMentorMaterId;
        case remoteWorkLocationId:
            return flexibleRemoteMentorMaterId;
        case absenceWorkLocationId:
            return homeMentorMaterId;
        default:
            return null;
    }
};

export default {
    getMyProfile,
    getMentomaterId
};
