import styled from 'styled-components';

const StyledCustomAutoComplete = styled.div``;

export const StyledCustomAutoCompleteDropdown = styled.div`
    width: 100%;
    background-color: var(--white);
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--marine20);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    max-height: 30vh;
    overflow: auto;

    .option:hover {
        cursor: pointer;
        background-color: var(--marine5);
        border-radius: 2px;
    }

    .option {
        width: 100%;
        margin-bottom: 4px;
        padding: 8px;
        display: flex;
        align-items: center;
    }

    .option > div {
        flex: 1;
    }

    .option:last-child {
        margin-bottom: 0px;
    }
`;

export default StyledCustomAutoComplete;
