import React from 'react';

import PropTypes from 'prop-types';

import { StyledFooter } from './styled/Footer.styled';

const Footer = ({ className }) => {
    return (
        <StyledFooter className={className}>
            <div>CollaboMate 2021</div>
            <div className="footer-right-section">
                <div>Privacy Policy</div>
                <div>Terms of Use</div>
            </div>
        </StyledFooter>
    );
};

Footer.propTypes = {
    className: PropTypes.string
};

export default Footer;
