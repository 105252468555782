import React from 'react';

import PropTypes from 'prop-types';

const HEADING_COLOR_VARS = {
    primaryBlue: '#03a8f5',
    darkNavy: '#082c43',
    black: '#111111',
    white: '#ffffff'
};

const Heading = ({ size, color = 'primary', text = '' }) => {
    const style = {
        color: HEADING_COLOR_VARS[color]
    };

    return (
        <>
            {size === 1 && <h1 style={style}>{text}</h1>}
            {size === 2 && <h2 style={style}>{text}</h2>}
            {size === 3 && <h3 style={style}>{text}</h3>}
            {size === 4 && <h4 style={style}>{text}</h4>}
            {size === 5 && <h5 style={style}>{text}</h5>}
        </>
    );
};

Heading.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    text: PropTypes.string
};

export default Heading;
