import { Helmet } from 'react-helmet-async';
import DashboardHeader from 'reports/components/DashboardHeader';
import Main from 'shared/components/layout/Main';

import adminTabs from './adminTabs';
import { StyledAdminDashboard } from './styled/AdminDashboard.styled';

const AdminDashboard = () => {
    const pageTitle = 'CollaboMate';

    return (
        <StyledAdminDashboard>
            <Helmet title={pageTitle} />
            <Main
                hasHeader={true}
                hasNavigation={true}
                hasHeading={false}
                hasCustomHeading={true}
                customHeadingComponent={<DashboardHeader tabs={adminTabs} />}
            />
        </StyledAdminDashboard>
    );
};

export default AdminDashboard;
