import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const createWorkLocation = async requestData => {
    const data = await httpService.post(url.CREATE_WORK_LOCATION(), {
        ...requestData
    });
    return data.data;
};

const updateWorkLocation = async (workLocationId, requestData) => {
    const data = await httpService.put(
        url.UPDATE_WORK_LOCATION(workLocationId),
        {
            ...requestData
        }
    );
    return data.data;
};

export default {
    createWorkLocation,
    updateWorkLocation
};
