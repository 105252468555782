import React from 'react';

import PropTypes from 'prop-types';

import { AllTabs } from './AllTabs';
import { StyledDashboardHeader } from './styled/DashboardHeader.styled';

const DashboardHeader = ({ tabs }) => {
    return (
        <StyledDashboardHeader>
            <div className="reports-dashboard-header-wrapper">
                <div className="nav-list-items">
                    <AllTabs tabs={tabs} />
                </div>
            </div>
        </StyledDashboardHeader>
    );
};

DashboardHeader.propTypes = {
    tabs: PropTypes.array
};

export default DashboardHeader;
