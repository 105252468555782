import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import profilesService from 'wfh/services/profilesService';

import { createWorkLocation, updateWorkLocation } from './workLocation';

const initialState = {
    currentProfile: {
        title: '',
        workFromHomeDays: {
            used: 0,
            total: 0
        },
        workLocations: [
            {
                id: undefined,
                workLocatioTypeId: undefined,
                startDate: undefined
            }
        ]
    },

    shouldRefetchProfile: true,
    isLoading: false
};

const setCurrentProfileWorkLocationsStateHelper = (state, action) => {
    return [
        ...state.currentProfile.workLocations.map(workLocation => {
            if (workLocation.id == action.payload.data.id) {
                workLocation.workLocationTypeId =
                    action.payload.data.workLocationTypeId;
            }
            return workLocation;
        })
    ];
};

export const getMyProfile = createAsyncThunk(
    'profiles/getMyProfile',
    async () => {
        const response = await profilesService.getMyProfile();
        return response;
    }
);

const { reducer: profilesReducer, actions } = createSlice({
    name: 'profiles',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getMyProfile.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getMyProfile.fulfilled, (state, action) => {
            state.currentProfile = action.payload.data;
            state.shouldRefetchProfile = false;
            state.isLoading = false;
        });
        builder.addCase(getMyProfile.rejected, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(createWorkLocation.fulfilled, (state, action) => {
            state.currentProfile.workLocations.push(action.payload.data);
            state.shouldRefetchProfile = true;
        });
        builder.addCase(updateWorkLocation.fulfilled, (state, action) => {
            state.currentProfile.workLocations =
                setCurrentProfileWorkLocationsStateHelper(state, action);
            state.shouldRefetchProfile = true;
        });
    }
});

export { profilesReducer, actions as profileActions };
