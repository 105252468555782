import React from 'react';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { msalConfig } from 'utils/loginServerConfigs';

import AllRoutes from './routes';

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
    return (
        <BrowserRouter>
            <MsalProvider instance={msalInstance}>
                <HelmetProvider>
                    <AllRoutes />
                </HelmetProvider>
            </MsalProvider>
        </BrowserRouter>
    );
};

export default App;
