const { default: styled } = require('styled-components');

const StyledButtonGroup = styled.div`
    .customButton {
        width: fit-content;
        height: 34px;
        background-color: var(--white);
        font-size: 14px;
        border: 1px solid var(--marine10);
        border-radius: 4px;
        cursor: pointer;
        margin-right: 4px;
        padding: 0 8px;
    }

    .active {
        border: 1px solid var(--marine10);
        background-color: var(--orange);
        color: var(--white);
    }

    .ant-btn.customButton:hover {
        border: 1px solid var(--orange);
        color: var(--black);
    }
`;

export default StyledButtonGroup;
