import styled from 'styled-components';

const StyledLayoutPage = styled.div`
    align-items: center;
    justify-content: center;

    .layout-label {
        font-size: 2em;
        min-height: 44px;
    }

    .layout {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        padding-top: 3em;
    }

    .error {
        margin-top: 100px;
    }
`;

export default StyledLayoutPage;
