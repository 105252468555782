import styled from 'styled-components';

const StyledUserRolesModal = styled.div`
    width: 100%;
    height: 30vh;
    & > * {
        padding: 0 32px;
    }

    .roles-label {
        margin-top: 12px;
        margin-bottom: 16px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.4em;
        color: var(--fauxBlack);
        opacity: 0.5;
    }

    .ant-select-selector {
        height: 40px !important;
    }
`;

export default StyledUserRolesModal;
