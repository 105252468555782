import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getLocationsWithAvailableSpaces = async requestDate => {
    const data = await httpService.get(url.GET_LOCATIONS(requestDate));
    return data.data;
};

const getOfficeLocations = async () => {
    const data = await httpService.get(url.GET_OFFICE_LOCATIONS());
    return data.data;
};

export default {
    getLocationsWithAvailableSpaces,
    getOfficeLocations
};
