import styled from 'styled-components';

const StyledWorkFromHomeReportTab = styled.div`
    padding-top: 20px;
    .selectors-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .selector {
            padding-right: 20px;
            .custom-select-div {
                margin: 0px;
            }
            .custom-select-div.wider {
                width: 325px;
                .ant-select {
                    width: 100%;
                    .ant-select-selector {
                        width: 100%;
                    }
                }
            }
            .custom-month-year-picker {
                margin: 0px;
                width: 150px !important;
            }
            :nth-child(3) {
                align-self: end;
            }
        }
    }
    .ant-table {
        padding-top: 20px;
        border-radius: 4px;
    }

    .ant-table-thead > tr > th {
        background: var(--marine5) !important;
    }

    .ant-spin-dot-item {
        background-color: var(--orange);
    }
`;

export default StyledWorkFromHomeReportTab;
