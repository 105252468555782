import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    weekOffset: 0,
    displayedWeek: [],
    weekSpan: ''
};

const { reducer: datePickerReducer, actions } = createSlice({
    name: 'datePicker',
    initialState,
    reducers: {
        nextWeek(state) {
            state.weekOffset++;
            state.displayedWeek = setWeek(state.weekOffset);
            state.weekSpan = getWeekSpan(state.displayedWeek);
        },
        prevWeek(state) {
            state.weekOffset--;
            state.displayedWeek = setWeek(state.weekOffset);
            state.weekSpan = getWeekSpan(state.displayedWeek);
        },
        currentWeek(state) {
            state.weekOffset = 0;
            state.displayedWeek = setWeek(state.weekOffset);
            state.weekSpan = getWeekSpan(state.displayedWeek);
        }
    }
});

export { datePickerReducer, actions as datePickerActions };

function setWeek(offset = 0) {
    let curr = new Date();
    curr.setDate(curr.getDate() + offset * 7);
    curr.setHours(0, 0, 0, 0);

    let showWeek = [];
    for (let i = 1; i <= 5; i++) {
        let first = curr.getDate() - curr.getDay() + i;
        let day = new Date(curr.setDate(first));

        showWeek.push(day.toISOString());
    }

    return showWeek;
}

function getWeekSpan(displayedWeek) {
    let displayedWeekLastIndex = displayedWeek ? displayedWeek.length - 1 : 0;
    let startDay = new Date(displayedWeek[0]);
    let endDay = new Date(displayedWeek[displayedWeekLastIndex]);

    if (!startDay || !endDay) {
        return '';
    }

    let startDayMonth = startDay.getMonth();
    let startDayYear = startDay.getFullYear();

    let endDayMonth = endDay.getMonth();
    let endDayYear = endDay.getFullYear();

    if (startDayYear !== endDayYear) {
        return `${
            startDay.toLocaleString('default', { month: 'short' }) +
            ' ' +
            startDay.getFullYear() +
            ' - ' +
            endDay.toLocaleString('default', { month: 'short' }) +
            ' ' +
            endDay.getFullYear()
        }`;
    }

    if (startDayMonth != endDayMonth) {
        return `${
            startDay.toLocaleString('default', { month: 'short' }) +
            ' - ' +
            endDay.toLocaleString('default', { month: 'short' }) +
            ' ' +
            endDay.getFullYear()
        }`;
    }

    return `${
        startDay.toLocaleString('default', { month: 'long' }) +
        ' ' +
        startDay.getFullYear()
    }`;
}
