import React from 'react';

import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import StyledButton from './styled/Button.styled';

const CustomButton = ({
    className,
    onClick,
    style,
    type,
    disabled,
    tooltipTitle,
    tooltipPlacement,
    children,
    size,
    icon
}) => {
    const btnClass = `button ${disabled ? 'btn-disabled ' : ''}`;
    return (
        <>
            <StyledButton className={className}>
                <Button
                    className={`${btnClass}`}
                    onClick={disabled ? undefined : onClick}
                    role="button"
                    style={style}
                    type={type}
                    size={size}
                    icon={icon}
                >
                    {tooltipTitle ? (
                        <Tooltip
                            title={tooltipTitle}
                            placement={tooltipPlacement}
                        >
                            {children}
                        </Tooltip>
                    ) : (
                        <>{children}</>
                    )}
                </Button>
            </StyledButton>
        </>
    );
};

CustomButton.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipTitle: PropTypes.node,
    tooltipPlacement: PropTypes.string,
    children: PropTypes.node,
    size: PropTypes.string,
    icon: PropTypes.element
};

export default CustomButton;
