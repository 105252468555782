import React from 'react';

import PropTypes from 'prop-types';

import { marine80 } from '../../../constants/generalConstants';

const SelectIcon = ({ size = 24, color = marine80, className }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="8" fill="#80939F" />
            <path
                d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11.65 14.65L8.86 11.86C8.54 11.54 8.76 11 9.21 11H14.8C15.25 11 15.47 11.54 15.15 11.85L12.36 14.64C12.16 14.84 11.84 14.84 11.65 14.65V14.65Z"
                fill="#E6EAEC"
            />
        </svg>
    );
};

SelectIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
};

export default SelectIcon;
