export const red = '#d93636';
export const grey = '#B9C0BF';
export const black = '#000000';
export const yellow = '#F2A20C';
export const marineBlue = '#012840';
export const marine20 = '#ccd4d9';
export const marine80 = '#345366';
export const fauxBlack = '#262729';
export const white = 'white';
export const darkNavy = '#082c43';
export const listHeaderHeight = '55.14px';
export const orange = '#f28627';
export const workWeekHeight = '67px';
export const unspecifiedWorkLocationId = 0;
export const officeWorkLocationId = 1;
export const homeWorkLocationId = 2;
export const remoteWorkLocationId = 3;
export const absenceWorkLocationId = 4;
export const companyHolidayWorkLocationId = 5;
export const officeHomeMentorMaterId = 1;
export const homeMentorMaterId = 2;
export const contractorMentorMaterId = 4;
export const flexibleRemoteMentorMaterId = 3;
export const flexDeskTypeId = 2;
export const wfoEmailType = 1;
export const deskTypes = {
    free: 'Free',
    flex: 'Flex',
    reserved: 'Reserved'
};
