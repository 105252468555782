import React, { useCallback } from 'react';

import TabsWrapper from '../../shared/components/DashboardTabs/TabsWrapper';
import { StyledAllTabs } from './styled/AllTabs.styled';

export const AllTabs = ({ tabs }) => {
    const handleTabChange = useCallback(() => {
        // This is intentional
    }, []);

    const tabElements = tabs.map(element => {
        return (
            <div
                key={element.label}
                label={`${element.label}`}
                disabled={element.disabled}
            >
                {element.child({ element })}
            </div>
        );
    });

    return (
        <StyledAllTabs>
            <TabsWrapper handleTabChange={handleTabChange} activeItemIndex={0}>
                {tabElements}
            </TabsWrapper>
        </StyledAllTabs>
    );
};
