import styled from 'styled-components';

const StyledUserGroupModal = styled.div`
    width: 100%;

    & > * {
        padding: 0 32px;
    }

    .group-name {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e6eaec;
    }
    .members {
        margin-top: 12px;
        margin-bottom: 16px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.4em;
        color: var(--fauxBlack);
        opacity: 0.5;
    }
    .members-list {
        height: 40vh;
        padding-bottom: 65px;
        overflow: auto;
    }
    .save-button {
        display: flex;
        justify-content: flex-end;
        padding: 16px 25px;
        background: rgba(230, 234, 236, 0.5);
        backdrop-filter: blur(10px);
    }
    .single-member {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }
    .remove-button {
        cursor: pointer;
    }
    .edit-user {
        cursor: pointer;
    }
    .error-text {
        margin-top: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: var(--red);
    }
`;

export const StyledUserGroupModalFooter = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 16px 25px;
    background: rgba(230, 234, 236, 0.5);
    backdrop-filter: blur(10px);
    border-radius: 0 0 20px 0;
`;

export const StyledUserGroupModalDropdown = styled.div`
    background-color: var(--white);
    padding: 4px;
    border-radius: 4px;
    border: 1px solid var(--marine20);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .option:hover {
        cursor: pointer;
        background-color: var(--marine5);
        border-radius: 2px;
    }

    .option {
        padding: 8px;
        display: flex;
        align-items: center;
    }
`;

export default StyledUserGroupModal;
