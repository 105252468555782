import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import reportService from 'reports/services/reportService';

const initialState = {
    workFromHomeReport: null,
    desksSummary: null,
    isLoadingReport: false
};

export const getWorkFromHomeReport = createAsyncThunk(
    'reports/getWorkFromHomeReport',
    async date => {
        const response = await reportService.getWorkFromHomeReport(date);
        return response;
    }
);

export const getDesksSummary = createAsyncThunk(
    'reports/getDesksSummary',
    async requestData => {
        const response = await reportService.getDesksSummary(requestData);
        return response;
    }
);

const { reducer: reportsReducer, actions } = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        clearReports(state) {
            state.workFromHomeReport = initialState.workFromHomeReport;
            state.desksSummary = initialState.desksSummary;
        }
    },
    extraReducers: builder => {
        builder.addCase(getWorkFromHomeReport.pending, (state, action) => {
            state.isLoadingReport = true;
        });
        builder.addCase(getWorkFromHomeReport.fulfilled, (state, action) => {
            state.workFromHomeReport = action.payload.data;
            state.isLoadingReport = false;
        });
        builder.addCase(getDesksSummary.pending, (state, action) => {
            state.isLoadingReport = true;
        });
        builder.addCase(getDesksSummary.fulfilled, (state, action) => {
            state.desksSummary = action.payload.data;
            state.isLoadingReport = false;
        });
    }
});

export { reportsReducer, actions as reportsActions };
