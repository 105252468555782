import { useState, useEffect, useCallback } from 'react';

import { Select, Space, Empty } from 'antd';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { getOfficeLocations } from 'redux/slices/locations';
import { getDesksSummary, reportsActions } from 'redux/slices/reports';
import { CustomDateSelector, CustomSelect } from 'shared/components';
import PieChart from 'shared/components/Charts/PieChart';
import Spinner from 'shared/components/Spinner';
import { deskTypes } from 'shared/constants/generalConstants';
import OfficeLocationsOption from 'wfh/modals/Locations/OfficeLocationOption';

import StyledDesksSummaryTab from './styled/DeskSummaryTab.styled';

const DesksSummaryTab = () => {
    const [selectedDate, setSelectedDate] = useState(
        dayjs().format('YYYY-MM-DD')
    );
    const locations = useSelector(state => state.locations.officeLocations);
    const { desksSummary, isLoadingReport } = useSelector(
        state => state.reports
    );
    const [selectedLocation, setSelectedLocation] = useState();
    const dispatch = useDispatch();
    const currentProfileLocation = useSelector(
        state => state.profiles.currentProfile.location
    );

    useEffect(() => {
        dispatch(getOfficeLocations());
        return () => {
            dispatch(reportsActions.clearReports());
        };
    }, []);

    useEffect(() => {
        setSelectedLocation(currentProfileLocation?.id);
    }, [currentProfileLocation]);

    useEffect(() => {
        if (selectedDate && selectedLocation) {
            dispatch(
                getDesksSummary({
                    date: selectedDate,
                    locationId: selectedLocation
                })
            );
        }
    }, [selectedLocation, selectedDate]);

    const dateChangeHandler = useCallback((name, date) => {
        setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
    }, []);

    const onSelectChange = useCallback((name, selectedLocationId) => {
        setSelectedLocation(selectedLocationId);
    }, []);

    const locationOptions = locations.map(x => (
        <Select.Option key={x.id} value={x.id}>
            <OfficeLocationsOption name={x.name} />
        </Select.Option>
    ));

    const getDeskTypeDisplayName = desks => {
        let type = '';
        switch (desks[0]?.type) {
            case deskTypes.free: {
                type = 'Unequipped';
                break;
            }
            case deskTypes.flex:
                type = 'Flex';
                break;

            case deskTypes.reserved:
                type = 'Office+Home';
                break;
            default:
                type = 'All';
                break;
        }

        return `${type}\nTotal ${desks.reduce((total, object) => {
            return total + object['count'];
        }, 0)}`;
    };

    return (
        <StyledDesksSummaryTab>
            <div className="selectors-section">
                <Space>
                    <CustomSelect
                        label="Office"
                        options={locationOptions}
                        value={selectedLocation}
                        onChange={onSelectChange}
                    ></CustomSelect>
                    <CustomDateSelector
                        label="Date"
                        name="date"
                        value={selectedDate}
                        onChange={dateChangeHandler}
                        allowClear={false}
                    ></CustomDateSelector>
                </Space>
            </div>
            <Spinner spinning={isLoadingReport}>
                {desksSummary?.some(x => x.count != 0) ? (
                    <div className="charts">
                        <div className="charts-left">
                            <PieChart
                                data={desksSummary.filter(x => x.type == 'All')}
                                statisticContent={getDeskTypeDisplayName(
                                    desksSummary.filter(x => x.type == 'All')
                                )}
                                innerRadius={0.7}
                                width={600}
                                statisticContentFontSize={20}
                            />
                        </div>
                        <div className="charts-right">
                            {Object.values(deskTypes).map(type => {
                                const desksByType = desksSummary.filter(
                                    x => x.type == type
                                );
                                return (
                                    <PieChart
                                        key={type}
                                        data={desksByType}
                                        width={500}
                                        height={150}
                                        statisticContent={getDeskTypeDisplayName(
                                            desksByType
                                        )}
                                    />
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <Empty
                        description={
                            selectedLocation
                                ? 'No desks information.'
                                : 'Select office.'
                        }
                    />
                )}
            </Spinner>
        </StyledDesksSummaryTab>
    );
};

export default DesksSummaryTab;
