import React from 'react';

import { Helmet } from 'react-helmet-async';
import DashboardHeader from 'reports/components/DashboardHeader';
import reportTabs from 'reports/reportTabs';

import Main from '../../shared/components/layout/Main';
import { StyledReportsDashboard } from './styled/ReportsDashboard.styled';

const ReportsDashboard = () => {
    const pageTitle = 'CollaboMate';

    return (
        <StyledReportsDashboard>
            <Helmet title={pageTitle} />
            <Main
                hasHeader={true}
                hasNavigation={true}
                hasHeading={false}
                hasCustomHeading={true}
                customHeadingComponent={<DashboardHeader tabs={reportTabs} />}
            />
        </StyledReportsDashboard>
    );
};

export default ReportsDashboard;
