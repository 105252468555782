import React, { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import TabItem from './TabItem';

const TabsWrapper = props => {
    const { activeItemIndex, handleTabChange } = props;
    const [state, setState] = useState({
        activeTab: props.children[activeItemIndex].props.label
    });

    const onClickTabItem = useCallback(
        tab => {
            setState({ activeTab: tab });
            handleTabChange(tab);
        },
        [handleTabChange]
    );

    const { children } = props;
    const { activeTab } = state;

    return (
        <div className="tabs">
            <ol className="tab-list">
                {children.map(child => {
                    const { label, disabled } = child.props;
                    return (
                        <TabItem
                            activeTab={activeTab}
                            key={label}
                            label={label}
                            onClickHandler={onClickTabItem}
                            disabled={disabled}
                        />
                    );
                })}
            </ol>
            <div className="tab-content">
                {children.map(child => {
                    if (child.props.label !== activeTab) return undefined;
                    return child.props.children;
                })}
            </div>
        </div>
    );
};

TabsWrapper.propTypes = {
    activeItemIndex: PropTypes.number,
    handleTabChange: PropTypes.func,
    children: PropTypes.array
};

export default TabsWrapper;
