import { useCallback, useEffect, useState } from 'react';

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    adminActions,
    getLocationWithNotifications,
    updateLocationNotifications
} from 'redux/slices/admin';
import CustomButton from 'shared/components/Button';
import { wfoEmailType } from 'shared/constants/generalConstants';
import { emailRegex } from 'shared/constants/regexConstants';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';
import { StyledUserGroupModalFooter } from 'wfh/modals/Groups/styled/UserGroupModal.styled';

import EmailChip from './EmailChip';
import EmailInput from './Emailnput';
import StyledLocationNotificationsModal from './styled/LocationNotificationsModal.styled';

const LocationNotificationsModal = ({
    className = '',
    closeModal,
    visible,
    locationId,
    ...rest
}) => {
    const dispatch = useDispatch();
    const modalNotification = useSelector(
        state => state.admin.modalNotification
    );
    const [inputValue, setInputValue] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        dispatch(getLocationWithNotifications(locationId));
    }, [locationId]);

    const updateNotifications = useCallback(() => {
        dispatch(updateLocationNotifications(modalNotification));
        closeModal();
    }, [modalNotification]);

    const footer = (
        <StyledUserGroupModalFooter>
            <CustomButton
                type="primary"
                className="save-button"
                onClick={updateNotifications}
            >
                Save
            </CustomButton>
        </StyledUserGroupModalFooter>
    );

    const isValidEmail = email => {
        return new RegExp(emailRegex).test(email);
    };

    const onChange = useCallback(
        e => {
            const email = e.target.value;
            setInputValue(email);
            if (isValidEmail(email)) {
                setError(null);
            }
        },
        [setInputValue]
    );

    const addHandler = useCallback(() => {
        if (!isValidEmail(inputValue)) {
            setError('Email is invalid');
        } else {
            const notification = {
                emailAddress: inputValue,
                notificationTypeId: wfoEmailType
            };
            dispatch(adminActions.addModalEmail(notification));
            setInputValue('');
        }
    }, [inputValue]);

    return (
        <Modal
            open={visible}
            onCancel={closeModal}
            footer={footer}
            title="Edit location notifications"
            centered
            className="modal"
            closeIcon={<CloseIcon />}
            {...rest}
        >
            <StyledLocationNotificationsModal>
                <div className="email-input">
                    <EmailInput
                        value={inputValue}
                        addHandler={addHandler}
                        onChange={onChange}
                    ></EmailInput>
                    {error && <p className="email-error">{error}</p>}
                </div>
                <div className="emails">Emails:</div>
                <ul>
                    {modalNotification.locationNotifications.map(x => (
                        <EmailChip
                            key={x.emailAddress}
                            notification={x}
                            className="single-member"
                        />
                    ))}
                </ul>
            </StyledLocationNotificationsModal>
        </Modal>
    );
};

LocationNotificationsModal.propTypes = {
    className: PropTypes.string,
    closeModal: PropTypes.func,
    visible: PropTypes.bool,
    locationId: PropTypes.string
};

export default LocationNotificationsModal;
