import apiUrls from 'apiUrls';

import httpService from '../../shared/services/httpService';

const getHolidays = async () => {
    const data = await httpService.get(apiUrls.GET_HOLIDAYS());
    return data.data;
};

export default {
    getHolidays
};
