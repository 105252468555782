import styled from 'styled-components';

const StyledEmailInput = styled.div`
    .group {
        padding-top: 10px;
        display: flex;
        flex-direction: row;
    }
`;

export default StyledEmailInput;
