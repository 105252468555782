import styled from 'styled-components';

import Background from '../../../shared/theme/assets/images/Background.svg';

const StyledMainDashboard = styled.div`
    .background {
        padding: 50px 3vw 17px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
        background: url(${Background});
        background-size: cover;
        background-color: var(--white);
        border-bottom: 1px solid var(--marine10);
        position: sticky;
        top: 0;
        z-index: 2;
    }
    .main-error {
        margin-top: 20px;
    }
    .main .ant-spin .ant-spin-dot {
        top: 180px;
    }
    .top-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 35px;
        margin-bottom: 35px;
    }
    .button .icon {
        margin-right: 13px;
    }
    .self {
        justify-self: start;
        align-self: end;
    }
    .w-100 {
        width: 100%;
    }

    .dates-section {
        display: grid;
        column-gap: 7px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        justify-items: center;
    }
    .alerts-border {
        animation: blink 1s;
        animation-iteration-count: 1;
    }

    @keyframes blink {
        50% {
            background-color: var(--cremeLight);
        }
    }
`;

export default StyledMainDashboard;
