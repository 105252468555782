/*istanbul ignore file*/
import React, { useCallback } from 'react';

import { useMsal } from '@azure/msal-react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { errorNotification } from 'shared/components/Notifications';
import MicrosoftIcon from 'shared/theme/assets/icons/MicrosoftIcon';
import { loginRequest } from 'utils/loginServerConfigs';

import authService from '../services/authService';
import StyledLoginButton from './styled/StyledLoginButton.styled';

const MicrosoftLoginButton = ({ isLoading, showSpinner }) => {
    const { instance } = useMsal();

    const handleCredentialResponse = async response => {
        const accessToken = response.accessToken;
        try {
            showSpinner(true);
            const response = await authService.microsoftLogin(accessToken);
            const responseData = response.data;
            const decodedToken = jwtDecode(accessToken);
            //if you need more props that are from token get them from here
            localStorage.setItem('token', responseData.token);
            localStorage.setItem(
                'profile',
                JSON.stringify({
                    userId: responseData.userId,
                    profileId: responseData.profileId,
                    directoryId: decodedToken.oid,
                    email: decodedToken.email,
                    name: decodedToken.name,
                    given_name: decodedToken.given_name,
                    family_name: decodedToken.family_name,
                    picture: responseData.profilePicture
                })
            );
            showSpinner(false);
        } catch (error) {
            errorNotification('Not authorized.', 5);
            showSpinner(false);
        }
    };

    const login = useCallback(() => {
        instance.loginPopup(loginRequest).then(handleCredentialResponse);
    }, [instance]);

    return (
        <StyledLoginButton
            disabled={isLoading}
            onClick={login}
            icon={<MicrosoftIcon />}
        >
            Sign in with Microsoft
        </StyledLoginButton>
    );
};

MicrosoftLoginButton.propTypes = {
    isLoading: PropTypes.bool,
    showSpinner: PropTypes.func
};

export default MicrosoftLoginButton;
