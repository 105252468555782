import styled from 'styled-components';

const StyledOfficeLocationsOption = styled.div`
    display: flex;
    align-items: center;

    .location-info {
        margin-left: 10px;
    }
    .spaces {
        opacity: 0.75;
        height: 22px;
    }

    .title {
        font-weight: bold;
        height: 22px;
    }
`;

export default StyledOfficeLocationsOption;
