import styled from 'styled-components';

const StyledDisplayDate = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 11px;
    color: var(--marine50);

    .day-name {
        font-size: 20px;
        color: var(--fauxBlack);
        border-radius: 50%;
        height: 40px;
        width: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
    }

    &.date-today {
        color: var(--orange);
    }

    &.date-today .day-name {
        color: var(--white);
        background-color: var(--orange);
    }

    &.date-passed,
    &.date-passed .day-name {
        color: var(--navy50);
    }
`;

export default StyledDisplayDate;
