import React from 'react';

import PropTypes from 'prop-types';

import { marineBlue } from '../../../constants/generalConstants';

const CloseIcon = ({
    size = 24,
    color = marineBlue,
    className = '',
    onClick
}) => {
    return (
        <svg
            onClick={onClick}
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
        </svg>
    );
};

CloseIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func
};

export default CloseIcon;
