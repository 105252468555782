import httpService from 'shared/services/httpService';

import url from '../../apiUrls';

const getWorkFromHomeCSVReport = async requestDate => {
    const data = await httpService.get(
        url.WorkFromHome_CSV_Report(requestDate)
    );
    return data.data;
};

const getWorkFromHomeReport = async requestDate => {
    const data = await httpService.get(url.WorkFromHome_Report(requestDate));
    return data.data;
};

const getDesksSummary = async requestData => {
    const data = await httpService.get(
        url.DESKS_SUMMARY(requestData.date, requestData.locationId)
    );
    return data.data;
};

export default {
    getWorkFromHomeReport,
    getWorkFromHomeCSVReport,
    getDesksSummary
};
