import React from 'react';

import { ConfigProvider } from 'antd';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import { configureStore } from './redux/index';
import * as serviceWorker from './registerServiceWorker';

import './index.css';

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        fontFamily:
                            "'Inter', 'Source-Sans-Pro', Helvetica, Arial, sans-serif"
                    }
                }}
            >
                <App />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>
);

serviceWorker.unregister();
