import holidayService from 'wfh/services/holidayService';

const { createAsyncThunk, createSlice } = require('@reduxjs/toolkit');

const initialState = {
    holidays: []
};

export const getHolidays = createAsyncThunk(
    'holidays/getHolidays',
    async () => {
        const response = await holidayService.getHolidays();
        return response;
    }
);

const { reducer: holidayReducer, actions } = createSlice({
    name: 'holidays',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getHolidays.fulfilled, (state, action) => {
            state.holidays = action.payload.data;
        });
    }
});

export { holidayReducer, actions as holidayActions };
