import styled from 'styled-components';

const StyledWeekPicker = styled.div`
    font-weight: bold;
    font-size: 24px;
    color: var(--darkNavy);
    display: flex;
    align-items: center;
    min-width: 380px;
    margin-left: 50px;
    justify-content: space-between;
    user-select: none;

    .picker-arrow {
        background-color: var(--marine10);
        border-radius: 50%;
        box-sizing: content-box;
        padding: 8px;
        cursor: pointer;
    }

    .picker-arrow:hover {
        background-color: var(--cream);
        fill: var(--marineBlue);
    }
`;

export default StyledWeekPicker;
