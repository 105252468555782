import styled from 'styled-components';

const StyledUserChip = styled.div`
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-self: center;

    .chip-image {
        background-size: cover;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 8px;
    }

    .chip-text {
        min-width: 110px;
    }

    .user-title {
        font-weight: 600;
        font-size: 12px;
        opacity: 0.5;
        text-align: left;
    }

    .user-name {
        font-size: 14px;
        color: var(--fauxBlack);
    }
`;

export default StyledUserChip;
