import { useCallback, useEffect, useState } from 'react';

import { Modal, Select } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getUserById, updateUserRoles } from 'redux/slices/users';
import { CustomSelect } from 'shared/components';
import CustomButton from 'shared/components/Button';
import { userRole } from 'shared/constants/roleConstants';
import { StyledUserGroupModalFooter } from 'wfh/modals/Groups/styled/UserGroupModal.styled';

import StyledUserRolesModal from './styled/UserRolesModal.styled';

const UserRolesModal = ({ visible, closeModal, editUserId }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.modalUser);
    const roles = useSelector(state => state.roles.roles);
    const [selectedRoles, setSelectedRoles] = useState();

    useEffect(() => {
        dispatch(getUserById(editUserId));
    }, [editUserId]);

    useEffect(() => {
        setSelectedRoles(user.roles?.map(x => x.id));
    }, [user]);

    const onRoleChange = useCallback((name, value) => {
        setSelectedRoles(value);
    }, []);

    const updateRoles = useCallback(() => {
        dispatch(
            updateUserRoles({
                userId: user.id,
                roles: selectedRoles
            })
        );
        closeModal();
    }, [user, selectedRoles]);

    const roleOptions = roles.map(x => (
        <Select.Option key={x.id} value={x.id} disabled={x.name == userRole}>
            {x.name}
        </Select.Option>
    ));

    const footer = (
        <StyledUserGroupModalFooter>
            <CustomButton
                type="primary"
                className="save-button"
                onClick={updateRoles}
            >
                Save
            </CustomButton>
        </StyledUserGroupModalFooter>
    );

    return (
        <Modal
            className="modal"
            title="Edit user roles"
            open={visible}
            onCancel={closeModal}
            footer={footer}
            centered
        >
            <StyledUserRolesModal>
                <div className="roles-label">Roles:</div>
                <CustomSelect
                    value={selectedRoles}
                    onChange={onRoleChange}
                    placeholder="Select roles"
                    options={roleOptions}
                    showSearch={false}
                    mode="multiple"
                ></CustomSelect>
            </StyledUserRolesModal>
        </Modal>
    );
};

UserRolesModal.propTypes = {
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    editUserId: PropTypes.string
};

export default UserRolesModal;
