import React from 'react';

import PropTypes from 'prop-types';

import { red } from '../../../constants/generalConstants';

const HomeIcon = ({ size = 24, color = red, className }) => {
    return (
        <svg
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z" />
        </svg>
    );
};

HomeIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
};

export default HomeIcon;
