//Static routes
export const STATIC_ROUTES_PATHS = {
    login: '/',
    main: '/main',
    unauthorized: '/unauthorized',

    //Admin Panel
    adminDashboard: '/admin',

    //Reports
    reportsDashboard: '/reports',
    office: '/office',

    //Layouts
    layouts: '/locations/desks/:deskId'
};
