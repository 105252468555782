import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import groupService from '../../wfh/services/groupsService';

const initialState = {
    profileGroups: [],
    modalGroup: {
        members: [],
        name: '',
        searchPhrase: ''
    },
    isLoadingGroups: false
};

const setProfileGroupStateHelper = (state, action) => {
    return [
        ...state.profileGroups.map(group => {
            if (group.id == action.payload.data.id) {
                group.name = action.payload.data.name;
                group.members = action.payload.data.members
                    ? [...action.payload.data.members]
                    : [];
            }
            return group;
        })
    ];
};

export const createGroup = createAsyncThunk(
    'groups/createGroup',
    async group => {
        let groupModel = {
            name: group.name,
            members: group.members
        };

        const groupResponse = await groupService.createGroup(groupModel);
        return groupResponse;
    }
);

export const updateGroup = createAsyncThunk(
    'groups/updateGroup',
    async group => {
        let groupModel = {
            name: group.name,
            members: group.members
        };

        const groupResponse = await groupService.updateGroup(
            group.id,
            groupModel
        );
        return groupResponse;
    }
);

export const getProfileGroups = createAsyncThunk(
    'groups/getProfileGroups',
    async () => {
        const response = await groupService.getGroups();
        return response;
    }
);

export const getGroupById = createAsyncThunk(
    'groups/GetGroupById',
    async groupId => {
        const response = await groupService.getGroupById(groupId);
        return response;
    }
);

export const deleteGroup = createAsyncThunk(
    'groups/DeleteGroup',
    async groupId => {
        const response = await groupService.deleteGroup(groupId);
        return response;
    }
);

const { reducer: groupsReducer, actions } = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        removeModalMember(state, action) {
            state.modalGroup.members = state.modalGroup.members.filter(
                member => member.id != action.payload
            );
        },
        addModalMember(state, action) {
            const item = action.payload;
            if (
                !state.modalGroup.members?.find(member => member.id === item.id)
            ) {
                state.modalGroup.members.push(item);
            }
        },
        changeGroupName(state, action) {
            state.modalGroup.name = action.payload;
        },
        changeSearchPhrase(state, action) {
            state.modalGroup.searchPhrase = action.payload;
        },
        clearModalGroup(state) {
            state.modalGroup = initialState.modalGroup;
        }
    },
    extraReducers: builder => {
        builder.addCase(createGroup.pending, (state, action) => {
            state.isLoadingGroups = true;
        });
        builder.addCase(createGroup.fulfilled, (state, action) => {
            state.profileGroups.push(action.payload.data);
            state.modalGroup = initialState.modalGroup;
            state.isLoadingGroups = false;
        });
        builder.addCase(createGroup.rejected, (state, action) => {
            state.isLoadingGroups = false;
        });
        builder.addCase(getProfileGroups.pending, (state, action) => {
            state.isLoadingGroups = true;
        });
        builder.addCase(getProfileGroups.fulfilled, (state, action) => {
            state.profileGroups = action.payload.data;
            state.isLoadingGroups = false;
        });
        builder.addCase(getProfileGroups.rejected, (state, action) => {
            state.isLoadingGroups = false;
        });
        builder.addCase(getGroupById.fulfilled, (state, action) => {
            state.modalGroup = {
                ...state.modalGroup,
                members: action.payload.data.members,
                name: action.payload.data.name
            };
        });
        builder.addCase(updateGroup.fulfilled, (state, action) => {
            state.profileGroups = setProfileGroupStateHelper(state, action);
        });
        builder.addCase(deleteGroup.fulfilled, (state, action) => {
            state.profileGroups = [
                ...state.profileGroups.filter(
                    group => group.id !== action.payload.data.id
                )
            ];
        });
    }
});

export { groupsReducer, actions as groupsActions };
