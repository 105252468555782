import { combineReducers } from 'redux';

import { adminReducer } from './slices/admin';
import { datePickerReducer } from './slices/datePicker';
import { groupsReducer } from './slices/groups';
import { holidayReducer } from './slices/holidays';
import { locationReducer } from './slices/locations';
import { membersReducer } from './slices/members';
import { profilesReducer } from './slices/profiles';
import { reportsReducer } from './slices/reports';
import { rolesReducer } from './slices/roles';
import { usersReducer } from './slices/users';
import { workLocationsReducer } from './slices/workLocation';

const appReducer = combineReducers({
    datePicker: datePickerReducer,
    groups: groupsReducer,
    members: membersReducer,
    profiles: profilesReducer,
    workLocations: workLocationsReducer,
    holidays: holidayReducer,
    locations: locationReducer,
    reports: reportsReducer,
    admin: adminReducer,
    users: usersReducer,
    roles: rolesReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export { rootReducer };
