const { clientId, tenantId, apiUrl } = {
    clientId: process.env.REACT_APP_CLIENT_ID,
    tenantId: process.env.REACT_APP_TENANT_ID
};

export const googleConfig = {
    client_id: clientId,
    scope: 'openid profile email'
};

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority: `https://login.microsoftonline.com/${tenantId}`,
        redirectUri: `${location.origin}/signin`
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
};

export const loginRequest = {
    scopes: [
        'openid',
        'profile',
        'email',
        `api://${tenantId}/collabomate/.default`
    ]
};
