import PropTypes from 'prop-types';
import OfficeIcon from 'shared/theme/assets/icons/OfficeIcon';

import StyledOfficeLocationsOption from './styled/OfficeLocationsOption.styled';

const OfficeLocationsOption = ({ name, availableSpaces }) => {
    return (
        <StyledOfficeLocationsOption>
            <OfficeIcon />
            <div className="location-info">
                <div className="title">{name}</div>
                {availableSpaces != null ? (
                    <div className="spaces">
                        {availableSpaces} spaces available
                    </div>
                ) : null}
            </div>
        </StyledOfficeLocationsOption>
    );
};

OfficeLocationsOption.propTypes = {
    name: PropTypes.string,
    availableSpaces: PropTypes.number
};

export default OfficeLocationsOption;
