import React, { useCallback, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import reportService from 'reports/services/reportService';
import { errorNotification } from 'shared/components/Notifications';
import Spinner from 'shared/components/Spinner';

import DownloadIcon from '../../../shared/theme/assets/icons/DownloadIcon';
import StyledDownloadButton from './styled/DownloadButton.styled';

const downloadElementHandler = (blob, linkRef, fileName) => {
    let link = linkRef.current;
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + '.csv';
    link.click();
    link.removeAttribute('href');
    link.removeAttribute('download');
};

const DownloadButton = props => {
    const { selectedMonth, setFieldErrors } = props;
    const [isLoading, setIsLoading] = useState(false);
    const downloadRef = useRef();

    const downloadClickHandler = useCallback(async () => {
        if (isLoading) {
            return;
        }

        //validation/
        if (isEmpty(selectedMonth)) {
            setFieldErrors(prev => ({
                ...prev,
                monthStartPeriodError: true
            }));

            return;
        }

        try {
            setIsLoading(true);
            const data = await reportService.getWorkFromHomeCSVReport(
                dayjs(selectedMonth).format('YYYY-MM-DD')
            );

            const blob = new Blob([data]);
            downloadElementHandler(
                blob,
                downloadRef,
                `WFH_Report_${dayjs(selectedMonth).format('YYYY-MM-DD')}`
            );
            setIsLoading(false);
        } catch (error) {
            errorNotification('Something went wrong', 5);
            setIsLoading(false);
        }
    }, [isLoading, selectedMonth, setFieldErrors]);

    const buttonIcon = isLoading ? <Spinner size="small" /> : <DownloadIcon />;

    return (
        <StyledDownloadButton>
            <button className="btn-normal" onClick={downloadClickHandler}>
                {buttonIcon}
                Download csv
            </button>
            <a id="download-hidden" ref={downloadRef} />
        </StyledDownloadButton>
    );
};

DownloadButton.propTypes = {
    selectedMonth: PropTypes.object,
    setFieldErrors: PropTypes.func
};

export default DownloadButton;
