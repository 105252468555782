import React from 'react';

import { Spin } from 'antd';
import PropTypes from 'prop-types';

import StyledSpinner from './styled/StyledSpinner';

const Spinner = ({ children, spinning = true, size = 'large' }) => {
    return (
        <div data-testid="test-spinner">
            <StyledSpinner>
                <Spin spinning={spinning} size={size}>
                    {children}
                </Spin>
            </StyledSpinner>
        </div>
    );
};

Spinner.propTypes = {
    children: PropTypes.node,
    spinning: PropTypes.bool,
    size: PropTypes.string
};

export default Spinner;
