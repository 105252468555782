import styled from 'styled-components';

const StyledNavigation = styled.div`
    .navigation-wrapper {
        width: 100%;
        height: 50px;
        margin-right: 50px;
    }
    .navigation-inner {
        display: flex;
        flex: 1;
        margin: 0;
        justify-content: center;
    }
`;

export { StyledNavigation };
