import React from 'react';

import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';
import { canNavigate } from 'utils/navigationHelper';

import authService from '../../auth/services/authService';

export const CustomRoute = props => {
    const location = useLocation();
    if (props.authenticated && !authService.getJwt()) {
        return (
            <Navigate
                path={props.path}
                to={STATIC_ROUTES_PATHS.login}
                state={{ from: location.pathname }}
            />
        );
    }
    const hasAccess = canNavigate(props.path);

    return hasAccess ? props.element : <Navigate to={'/unauthorized'} />;
};

CustomRoute.propTypes = {
    authenticated: PropTypes.bool,
    path: PropTypes.string,
    element: PropTypes.node
};
