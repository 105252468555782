import styled from 'styled-components';

const StyledUsersTab = styled.div`
    padding-top: 20px;

    .ant-table {
        border-radius: 4px;
        margin: 20px 0;
    }

    .ant-table-thead > tr > th {
        background: var(--marine5) !important;
    }

    .ant-spin-dot-item {
        background-color: var(--orange);
    }
    .filters {
        display: flex;
        justify-content: space-between;
    }

    .ant-select-selector {
        height: 40px !important;
    }
`;

export default StyledUsersTab;
