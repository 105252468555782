import styled from 'styled-components';

const StyledEmailChip = styled.div`
    display: flex;
    div:last-child {
        margin-left: auto;
    }

    .chip-icon {
        background-size: cover;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .email {
        font-size: 16px;
    }

    .mail-icon {
        font-size: 20px;
    }

    .remove-button {
        cursor: pointer;
    }
`;

export default StyledEmailChip;
