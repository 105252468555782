import NotificationsTab from 'admin/notifications/NotificationsTab';
import UsersTab from 'admin/users/UsersTab';

const adminTabs = [
    {
        label: 'Users',
        value: 1,
        disabled: false,
        child: props => <UsersTab />
    },
    {
        label: 'Notifications',
        value: 2,
        disabled: false,
        child: props => <NotificationsTab></NotificationsTab>
    }
];

export default adminTabs;
