import { useCallback, useEffect, useState } from 'react';

import { Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationsWithAvailableSpaces } from 'redux/slices/locations';
import ButtonGroup from 'shared/components/ButtonGroup';
import CustomSelect from 'shared/components/Select';
import CloseIcon from 'shared/theme/assets/icons/CloseIcon';
import DeskChip from 'wfh/components/DeskChip';

import OfficeLocationsOption from './OfficeLocationOption';
import StyledOfficeLocationModal from './styled/OfficeLocationModal.styled';

const OfficeLocationModal = ({
    className = '',
    closeModal,
    visible,
    date,
    getDesk,
    ...rest
}) => {
    const locations = useSelector(state => state.locations.locations);
    const dispatch = useDispatch();
    const defaultLocation = locations.find(x => x.isDefault) ?? locations[0];
    const [selectedOption, setSelectedOption] = useState();
    const [floors, setFloors] = useState([]);
    const [desks, setDesks] = useState([]);

    useEffect(() => {
        if (visible) {
            dispatch(getLocationsWithAvailableSpaces(date));
        }
    }, [date, visible]);

    const getFloorLabel = useCallback((desk, showFloor) => {
        return `${floorSuffix(desk)} ${showFloor ? 'floor' : ''}${
            desk?.building ? ` (${desk.building})` : ''
        }`;
    }, []);

    useEffect(() => {
        setSelectedOption(defaultLocation);
        setFloors([
            ...new Set(defaultLocation?.desks.map(desk => getFloorLabel(desk)))
        ]);
        setDesks(defaultLocation?.desks);
    }, [defaultLocation, getFloorLabel]);

    const changeSelection = useCallback(
        (name, selectedLocationId) => {
            const location = locations.find(x => x.id == selectedLocationId);
            setSelectedOption(location);
            setFloors([
                ...new Set(
                    location?.desks.map(desk => getFloorLabel(desk, false))
                )
            ]);
            setDesks(location?.desks);
        },
        [locations, getFloorLabel]
    );

    const onFloorChange = useCallback(
        floor => {
            if (floor == 'All Floors') {
                setDesks(selectedOption?.desks);
            } else {
                const floorDesks = selectedOption?.desks.filter(
                    x => getFloorLabel(x) == floor
                );
                setDesks(floorDesks);
            }
        },
        [selectedOption, getFloorLabel]
    );

    const selectOptions = locations.map(x => (
        <Select.Option key={x.id} value={x.id}>
            <OfficeLocationsOption
                name={x.name}
                availableSpaces={x.desks.length}
            ></OfficeLocationsOption>
        </Select.Option>
    ));

    const floorSuffix = desk => {
        let floor = desk.floor;
        if (floor != 'All Floors' && desk.floorSection == null) {
            let j = floor % 10,
                k = floor % 100;
            if (j == 1 && k != 11) {
                return floor + 'st';
            }
            if (j == 2 && k != 12) {
                return floor + 'nd';
            }
            if (j == 3 && k != 13) {
                return floor + 'rd';
            }

            return floor + 'th';
        }
        return `${desk.floor}${desk.floorSection}`;
    };

    return (
        <Modal
            open={visible}
            onCancel={closeModal}
            footer={null}
            title="Select office location"
            centered
            className="modal"
            closeIcon={<CloseIcon />}
            {...rest}
        >
            <StyledOfficeLocationModal
                className={`office-location-modal ${className}`}
            >
                <div className="location-select">
                    <CustomSelect
                        label="Location"
                        name="location"
                        required={true}
                        options={selectOptions}
                        defaultValue={defaultLocation?.id}
                        onChange={changeSelection}
                        value={selectedOption?.id}
                    ></CustomSelect>
                </div>
                {desks?.length > 0 && (
                    <div>
                        <div>Floor</div>
                        <ButtonGroup
                            buttons={
                                floors.length > 1
                                    ? ['All Floors', ...floors]
                                    : ['All Floors']
                            }
                            doSomethingAfterClick={onFloorChange}
                            defaultSelection="All Floors"
                            location={selectedOption}
                        ></ButtonGroup>
                        <div className="desks">DESKS</div>

                        <div className="location-desks">
                            {desks?.map(desk => {
                                return (
                                    <DeskChip
                                        key={desk.id}
                                        desk={desk}
                                        getDesk={getDesk}
                                        label={
                                            floors.length > 1
                                                ? getFloorLabel(desk, true)
                                                : ''
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
                {desks?.length == 0 && (
                    <div className="no-desks">
                        All flex desks are booked for this day. If you have an
                        urgent need to work from the office, please contact the
                        Office Assistant.
                    </div>
                )}
            </StyledOfficeLocationModal>
        </Modal>
    );
};

OfficeLocationModal.propTypes = {
    className: PropTypes.string,
    closeModal: PropTypes.func,
    visible: PropTypes.bool,
    date: PropTypes.string,
    getDesk: PropTypes.func
};

export default OfficeLocationModal;
