import { PlusOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';

import StyledEmailInput from './styled/EmailInput.styled';

const EmailInput = ({ value, addHandler, onChange }) => {
    return (
        <StyledEmailInput>
            <Input.Group compact className="group" size="large">
                <Input
                    type="email"
                    placeholder="Email address"
                    onChange={onChange}
                    value={value}
                    onPressEnter={addHandler}
                />
                <Button
                    size="large"
                    icon={<PlusOutlined />}
                    onClick={addHandler}
                ></Button>
            </Input.Group>
        </StyledEmailInput>
    );
};

EmailInput.propTypes = {
    value: PropTypes.string,
    addHandler: PropTypes.func,
    onChange: PropTypes.func
};

export default EmailInput;
