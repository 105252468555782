import { useCallback, useEffect, useState } from 'react';

import { EditTwoTone } from '@ant-design/icons';
import { Button, Select, Table, Tag } from 'antd';
import { debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRoles } from 'redux/slices/roles';
import { getAllUsers, usersActions } from 'redux/slices/users';
import { CustomSelect } from 'shared/components';
import CustomInput from 'shared/components/Input';
import { darkNavy } from 'shared/constants/generalConstants';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';

import StyledUsersTab from './styled/UsersTab.styled';
import UserRolesModal from './UserRolesModal';

const UsersTab = () => {
    const { users, isLoading, currentPaging } = useSelector(
        state => state.users
    );
    const roles = useSelector(state => state.roles.roles);
    const [searchPhrase, setSearchPhrase] = useState();
    const [selectedRoles, setSelectedRoles] = useState();
    const dispatch = useDispatch();
    const [rolesModalOpen, setRolesModalOpen] = useState(false);
    const [editUserId, setEditUserId] = useState();
    const [sortProps, setSortProps] = useState();

    const initialPaging = {
        skip: 0,
        pageSize: 20
    };

    useEffect(() => {
        dispatch(getAllUsers(initialPaging));
        dispatch(getAllRoles());
        return () => {
            dispatch(usersActions.clearUsers());
        };
    }, []);

    const columns = [
        {
            key: 'name',
            title: 'Name',
            dataIndex: 'name',
            width: 300,
            sorter: true
        },
        {
            key: 'emailAddress',
            title: 'Email',
            dataIndex: 'emailAddress',
            width: 300,
            sorter: true
        },
        {
            key: 'roles',
            title: 'Roles',
            dataIndex: 'roles',
            render: (_, { roles }) => (
                <>
                    {roles?.map(role => {
                        return (
                            <Tag key={role.id} color={darkNavy}>
                                {role.name.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, { key }) => (
                <Button
                    icon={<EditTwoTone twoToneColor={darkNavy} />}
                    size="large"
                    shape="circle"
                    value={key}
                    onClick={onClickEdit}
                ></Button>
            ),
            width: 50
        }
    ];
    const toggleRolesModal = useCallback(() => {
        setRolesModalOpen(() => !rolesModalOpen);
    }, [rolesModalOpen]);

    const onClickEdit = event => {
        setEditUserId(event.currentTarget.value);
        toggleRolesModal();
    };

    const debouncedSearch = useCallback(
        debounce(value => {
            dispatch(usersActions.clearUsers());
            searchHandler({
                ...initialPaging,
                searchPhrase: value,
                selectedRoles,
                ...sortProps
            });
        }, 500),
        [selectedRoles, sortProps]
    );

    const onChangeInput = useCallback(
        event => {
            setSearchPhrase(event.target.value);
            debouncedSearch(event.target.value);
        },
        [setSearchPhrase, debouncedSearch]
    );

    const onSelectChange = useCallback(
        (name, value) => {
            setSelectedRoles(value);
            dispatch(usersActions.clearUsers());
            searchHandler({
                ...initialPaging,
                searchPhrase,
                selectedRoles: value,
                ...sortProps
            });
        },
        [searchPhrase, sortProps]
    );

    const searchHandler = pageParams => {
        dispatch(getAllUsers(pageParams));
    };

    const tableData = users.map(x => {
        return { ...x, key: x.id };
    });

    // infinite scroll handling
    const boundaryRef = useInfiniteScroll({
        onLoadMore: () => {
            searchHandler({
                ...initialPaging,
                skip: currentPaging.skip + initialPaging.pageSize,
                searchPhrase,
                selectedRoles,
                ...sortProps
            });
        },
        isLoading,
        hasMore: users.length < currentPaging?.totalCount
    });

    const rolesOptions = roles.map(x => (
        <Select.Option key={x.id} value={x.id}>
            {x.name}
        </Select.Option>
    ));

    const handleSortChange = useCallback(
        (pagination, filters, sorter) => {
            dispatch(usersActions.clearUsers());
            setSortProps({
                sortBy: sorter.field,
                sortAsc: sorter.order == 'ascend'
            });
            searchHandler({
                ...initialPaging,
                searchPhrase,
                selectedRoles,
                sortBy: sorter.field,
                sortAsc: sorter.order == 'ascend'
            });
        },
        [searchPhrase, selectedRoles]
    );

    return (
        <StyledUsersTab>
            <div className="filters">
                <CustomInput
                    placeholder="Name or email"
                    onChange={onChangeInput}
                    value={searchPhrase}
                    allowClear
                    hasInput={true}
                ></CustomInput>
                <CustomSelect
                    placeholder="Select roles"
                    options={rolesOptions}
                    mode="multiple"
                    allowClear
                    onChange={onSelectChange}
                    showSearch={false}
                ></CustomSelect>
            </div>
            <Table
                dataSource={tableData}
                columns={columns}
                loading={isLoading}
                bordered
                pagination={false}
                onChange={handleSortChange}
            ></Table>
            <section ref={boundaryRef} />
            {rolesModalOpen && (
                <UserRolesModal
                    visible={rolesModalOpen}
                    closeModal={toggleRolesModal}
                    editUserId={editUserId}
                ></UserRolesModal>
            )}
        </StyledUsersTab>
    );
};

export default UsersTab;
