import styled from 'styled-components';

const StyledLocationChip = styled.div`
    width: 100%;
    background-color: var(--white);
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid var(--marine20);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-self: center;

    &:hover {
        cursor: ${props => (props.editable ? 'pointer' : 'initial')};
    }

    &:hover .dropdown {
        background-color: var(--cream);
        fill: var(--marineBlue);
    }

    .work-from {
        font-weight: 600;
        font-size: 14px;
        color: var(--fauxBlack);
        line-height: 129%;
    }

    .location {
        font-size: 12px;
        color: var(--fauxBlack);
        line-height: 133%;
        opacity: 0.75;
    }

    .location-icon {
        margin-right: 8px;
    }

    .dropdown {
        background-color: var(--marine10);
        border-radius: 50%;
        margin-left: auto;
        margin-right: 0;
        align-self: center;
    }

    &.date-passed {
        background-color: var(--marine5);
        box-shadow: none;
        border: 1px solid var(--marine10);
    }

    &.date-passed .location {
        opacity: 0.25;
    }

    &.date-passed .work-from {
        opacity: 0.5;
    }

    &.date-passed .icon {
        fill: var(--marine20);
        background-color: var(--marine5);
    }

    &.date-passed:hover {
        cursor: initial;
    }
`;

export default StyledLocationChip;
