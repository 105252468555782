import locationsService from 'wfh/services/locationsService';

const { createAsyncThunk, createSlice } = require('@reduxjs/toolkit');

const initialState = {
    locations: [],
    officeLocations: []
};

export const getLocationsWithAvailableSpaces = createAsyncThunk(
    'locations/getLocationsWithAvailableSpaces',
    async date => {
        const response = await locationsService.getLocationsWithAvailableSpaces(
            date
        );
        return response;
    }
);

export const getOfficeLocations = createAsyncThunk(
    'locations/getOfficeLocations',
    async () => {
        const response = await locationsService.getOfficeLocations();
        return response;
    }
);

const { reducer: locationReducer, actions } = createSlice({
    name: 'locations',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            getLocationsWithAvailableSpaces.fulfilled,
            (state, action) => {
                state.locations = action.payload.data;
            }
        );
        builder.addCase(getOfficeLocations.fulfilled, (state, action) => {
            state.officeLocations = action.payload.data;
        });
    }
});

export { locationReducer, actions as locationActions };
