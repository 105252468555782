import styled from 'styled-components';

const StyledDashboardHeader = styled.div`
    .reports-dashboard-header-wrapper {
        display: flex;
        flex-direction: column;
        height: 110px;
        padding: 74px 90px 0px 90px;
    }
    .reports-dashboard-header-upper-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .reports-dashboard-header-title {
        display: flex;
        align-items: center;
        font-size: 22px;
        line-height: 28px;
        color: white;
    }
    .reports-dashboard-header-buttons .button {
        margin-top: 17px;
    }
    .reports-dashboard-header-upper-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .reports-dashboard-header-buttons .button {
        margin-top: 17px;
    }
    .nav-list-items {
        display: flex;
        margin-bottom: 0px;
        padding: 0px;
    }
    .nav-list-items > div {
        width: 100%;
    }
    .nav-list-items .spinner-div .ant-spin.ant-spin-lg.ant-spin-spinning {
        padding-top: 100px;
        display: flex;
        justify-content: center;
    }
`;

export { StyledDashboardHeader };
