import apiUrls from 'apiUrls';
import httpService from 'shared/services/httpService';

const getAllRoles = async () => {
    const data = await httpService.get(apiUrls.GET_ALL_ROLES());
    return data.data;
};

export default {
    getAllRoles
};
