import { isEmpty } from 'lodash';

import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const searchPeople = async searchPhrase => {
    const skip = 0;
    const take = 20;
    let baseParams = `Skip=${skip}&Take=${take}`;

    //if there is given search string
    if (!isEmpty(searchPhrase)) {
        baseParams += `&Query=${searchPhrase}`;
    }

    const data = await httpService.get(url.SEARCH_PEOPLE(baseParams));
    return data.data;
};

const searchGroups = async searchPhrase => {
    const skip = 0;
    const take = 20;
    let baseParams = `Skip=${skip}&Take=${take}`;

    //if there is given search string
    if (!isEmpty(searchPhrase)) {
        baseParams += `&Query=${searchPhrase}`;
    }

    const data = await httpService.get(url.SEARCH_GROUPS(baseParams));
    return data.data;
};

const searchMembers = async searchPhrase => {
    const skip = 0;
    const take = 20;
    let baseParams = `Skip=${skip}&Take=${take}`;

    //if there is given search string
    if (!isEmpty(searchPhrase)) {
        baseParams += `&Query=${searchPhrase}`;
    }

    const data = await httpService.get(url.SEARCH_MEMBERS(baseParams));
    return data.data;
};

const createProfileMember = async requestData => {
    const data = await httpService.post(url.CREATE_PROFILE_MEMBER(), {
        ...requestData
    });
    return data.data;
};

const getProfileMembers = async () => {
    const data = await httpService.get(url.GET_PROFILE_MEMBERS());
    return data.data;
};

const deleteProfileMember = async profileMemberId => {
    const data = await httpService.delete(
        url.DELETE_PROFILE_MEMBER(profileMemberId)
    );
    return data.data;
};

export default {
    searchPeople,
    searchGroups,
    searchMembers,
    createProfileMember,
    getProfileMembers,
    deleteProfileMember
};
