import React from 'react';

import PropTypes from 'prop-types';

import { marineBlue } from '../../../constants/generalConstants';

const DoneIcon = ({ size = 18, color = marineBlue, className }) => {
    return (
        <svg
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 18 18"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.59994 11.9288L3.97494 9.30378C3.8363 9.16342 3.64723 9.08442 3.44994 9.08442C3.25265 9.08442 3.06358 9.16342 2.92494 9.30378C2.63244 9.59628 2.63244 10.0613 2.92494 10.3538L6.06744 13.4963C6.35994 13.7888 6.83244 13.7888 7.12494 13.4963L15.0749 5.55379C15.3674 5.26129 15.3674 4.79629 15.0749 4.50379C14.9363 4.36342 14.7472 4.28442 14.5499 4.28442C14.3527 4.28442 14.1636 4.36342 14.0249 4.50379L6.59994 11.9288Z" />
        </svg>
    );
};

DoneIcon.propTypes = {
    size: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string
};

export default DoneIcon;
