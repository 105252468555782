import jwtDecode from 'jwt-decode';
import {
    administratorRole,
    officeAssistantRole
} from 'shared/constants/roleConstants';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';

export const canNavigate = route => {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    switch (route) {
        // Administrator
        case STATIC_ROUTES_PATHS.reportsDashboard:
        case STATIC_ROUTES_PATHS.adminDashboard:
            return checkRole(administratorRole);

        // Office assistant
        case STATIC_ROUTES_PATHS.office:
            return checkRole(officeAssistantRole);

        default:
            return true;
    }
};

export const checkRole = allowedRole => {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    const decodedToken = jwtDecode(token);
    const rolesFromToken = decodedToken.role;

    return rolesFromToken.includes(allowedRole);
};

export const showNavigation = () => {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    const decodedToken = jwtDecode(token);
    const rolesFromToken = decodedToken.role;

    return [officeAssistantRole, administratorRole].some(r =>
        rolesFromToken.includes(r)
    );
};
