import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import membersService from '../../wfh/services/membersService';
import { createWorkLocation, updateWorkLocation } from './workLocation';

const initialState = {
    searchResult: [],
    profileMembers: [],
    isLoadingMembers: false
};

export const searchPeople = createAsyncThunk(
    'members/searchPeople',
    async searchPhrase => {
        const response = await membersService.searchPeople(searchPhrase);
        return response;
    }
);

export const searchGroups = createAsyncThunk(
    'members/searchGroups',
    async searchPhrase => {
        const response = await membersService.searchGroups(searchPhrase);
        return response;
    }
);

export const searchMembers = createAsyncThunk(
    'members/searchMembers',
    async searchPhrase => {
        const response = await membersService.searchMembers(searchPhrase);
        return response;
    }
);

export const createProfileMember = createAsyncThunk(
    'profiles/createProfileMember',
    async profile => {
        const profileResponse = await membersService.createProfileMember(
            profile
        );
        return profileResponse;
    }
);

export const getProfileMembers = createAsyncThunk(
    'profiles/getProfilesMembers',
    async () => {
        const response = await membersService.getProfileMembers();
        return response;
    }
);

export const deleteProfileMember = createAsyncThunk(
    'profiles/deleteProfileMember',
    async (profileMemberId, thunkAPI) => {
        const response = await membersService.deleteProfileMember(
            profileMemberId
        );
        await thunkAPI.dispatch(getProfileMembers());
        return response;
    }
);

const setCurrentProfileWorkLocationInGroupMembers = (state, action) => {
    state.profileMembers.forEach(profileMember => {
        if (!profileMember.isGroupMember) return;
        profileMember.members.forEach(member => {
            if (
                !member?.profile?.id ||
                member.profile.id != action.payload.data.profileId
            )
                return;
            if (action.type == 'workLocations/createWorkLocation/fulfilled') {
                member.profile.workLocations.push(action.payload.data);
                return;
            }
            const today = dayjs().format('YYYY-MM-DD');
            const defaultWorkLocation = member.profile.workLocations.find(
                wl =>
                    !wl.isDefinedByUser &&
                    wl.startDate <= today &&
                    wl.endDate >= today
            );
            if (
                action.payload.data.workLocationTypeId ==
                    defaultWorkLocation.workLocationTypeId &&
                action.payload.data.deskId == null
            ) {
                member.profile.workLocations =
                    member.profile.workLocations.filter(
                        workLocation =>
                            workLocation.id != action.payload.data.id
                    );
            } else {
                let workLocationToUpdate = member.profile.workLocations.find(
                    workLocation => workLocation.id == action.payload.data.id
                );
                workLocationToUpdate.workLocationTypeId =
                    action.payload.data.workLocationTypeId;
                workLocationToUpdate.title = action.payload.data.title;
                workLocationToUpdate.location = action.payload.data.location;
            }
        });
    });
};

const { reducer: membersReducer, actions } = createSlice({
    name: 'members',
    initialState,
    reducers: {
        clearSearchResults(state, action) {
            state.searchResult = [];
        }
    },
    extraReducers: builder => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(searchMembers.fulfilled, (state, action) => {
            state.searchResult = [...action.payload.data];
        });
        builder.addCase(searchPeople.fulfilled, (state, action) => {
            state.searchResult = [...action.payload.data];
        });
        builder.addCase(createProfileMember.pending, (state, action) => {
            state.isLoadingMembers = true;
        });
        builder.addCase(createProfileMember.fulfilled, (state, action) => {
            if (action.payload.data.isGroupMember) {
                state.profileMembers.push(action.payload.data);
            } else {
                state.profileMembers.unshift(action.payload.data);
            }
            state.isLoadingMembers = false;
        });
        builder.addCase(createProfileMember.rejected, (state, action) => {
            state.isLoadingMembers = false;
        });
        builder.addCase(getProfileMembers.pending, (state, action) => {
            state.isLoadingMembers = true;
        });
        builder.addCase(getProfileMembers.fulfilled, (state, action) => {
            state.profileMembers = action.payload.data.sort(function (x, y) {
                if (x.isGroupMember === y.isGroupMember) {
                    return 0;
                }
                return x ? 1 : -1;
            });
            state.isLoadingMembers = false;
        });
        builder.addCase(getProfileMembers.rejected, (state, action) => {
            state.isLoadingMembers = false;
        });
        builder.addCase(updateWorkLocation.fulfilled, (state, action) => {
            setCurrentProfileWorkLocationInGroupMembers(state, action);
        });
        builder.addCase(createWorkLocation.fulfilled, (state, action) => {
            setCurrentProfileWorkLocationInGroupMembers(state, action);
        });
    }
});

export { membersReducer, actions as membersActions };
