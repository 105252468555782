import React from 'react';

import PropTypes from 'prop-types';

const ArrowDropDownIcon = ({ size = 24, className = '' }) => {
    return (
        <svg
            className={`icon ${className}`}
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="#7F919E"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8.70615 11.4137L11.2961 14.0037C11.6861 14.3937 12.3161 14.3937 12.7061 14.0037L15.2961 11.4137C15.9261 10.7837 15.4761 9.70374 14.5861 9.70374H9.40615C8.51615 9.70374 8.07615 10.7837 8.70615 11.4137Z" />
        </svg>
    );
};

ArrowDropDownIcon.propTypes = {
    size: PropTypes.number,
    className: PropTypes.string
};

export default ArrowDropDownIcon;
