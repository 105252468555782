/*istanbul ignore file*/
import React, { useCallback, useEffect, useState } from 'react';

import { Navigate, useLocation } from 'react-router';
import { STATIC_ROUTES_PATHS } from 'shared/constants/staticRoutesPaths';

import { errorNotification } from '../../shared/components/Notifications';
import Spinner from '../../shared/components/Spinner';
import MicrosoftLoginButton from '../components/MicrosoftLoginButton';
import authService from '../services/authService';
import StyledLogin from './styled/Login.styled';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const errorTokenExp = localStorage.getItem('errorTokenExp');
    const location = useLocation();
    const redirectUrl = location.state?.from;
    useEffect(() => {
        if (localStorage.getItem('logout')) {
            localStorage.removeItem('logout');
        }
    }, []);

    useEffect(() => {
        if (errorTokenExp) {
            errorNotification(errorTokenExp, 5);
            localStorage.removeItem('errorTokenExp');
        }
    }, [errorTokenExp]);

    const showSpinner = useCallback(
        state => {
            setIsLoading(state);
        },
        [setIsLoading]
    );

    return (
        <>
            {!authService.getJwt() ? (
                <StyledLogin>
                    <div className="login-layer">
                        <div className="login-box">
                            <div>
                                <div className="header-section">
                                    CollaboMate
                                </div>
                                <div className="slogan-section">
                                    {isLoading && <Spinner />}
                                </div>
                            </div>
                            <MicrosoftLoginButton
                                isLoading={isLoading}
                                showSpinner={showSpinner}
                            />
                        </div>
                    </div>
                </StyledLogin>
            ) : (
                <Navigate
                    exact={true}
                    to={{
                        pathname: redirectUrl ?? STATIC_ROUTES_PATHS.main
                    }}
                />
            )}
        </>
    );
};

export default Login;
