import styled from 'styled-components';

import { CustomModal } from '../Modal';

const StyledCustomConfirmationModal = styled(CustomModal)`
    max-width: 381px;
    .ant-modal-header {
        margin: 0px 20px;
        padding-top: 21px;
        padding-bottom: 8px;
        .custom-modal-header {
            display: flex;
            justify-content: center;
            .icon-holder {
                position: absolute;
                right: 20px;
            }
        }
    }
    .ant-modal-content > .ant-modal-close {
        padding: 0 5px !important;
        margin: 0;
    }
    .ant-modal-body {
        margin: 0px 20px;
        .modal-content {
            padding-top: 24px;
            padding-bottom: 45px;
            .secondary-title {
                font-size: 18px;
                font-style: normal;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
            }
            .inner-modal-content {
                padding-top: 9px;
                font-size: 14px;
                font-style: normal;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
    .ant-modal-footer {
        margin: 0px 20px 0px 10px;
        border-top: none;
        padding: 100px 15px 10px 1px;
        .custom-modal-footer {
            display: flex;
            justify-content: space-between;
            width: 98%;
            .button-section {
                .button {
                    width: 60px;
                    height: 20px;
                }
            }
        }
    }
`;

export { StyledCustomConfirmationModal };
