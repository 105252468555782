import styled from 'styled-components';

const StyledHeader = styled.div`
    z-index: 3;
    position: fixed;
    top: 0px;
    width: 100%;
    height: 50px;
    padding: 0 3vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    background: var(--headerBackground);
    backdrop-filter: blur(100px);
    color: var(--fauxBlack);

    .header-navigation {
        display: inline-flex;
    }
    .header-profile {
        display: flex;
    }
    .header-profile-image {
        background-size: cover;
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        display: block;
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
    .header-profile-submenu {
        margin-top: 40px;
        position: absolute;
        right: 45px;
    }
    .header-dropdown {
        cursor: pointer;
        background-color: var(--transparentColor);
    }
    .header-wfh {
        margin: 0 24px;
    }
    .header-wfh p {
        display: inline-block;
        margin-right: 8px;
        font-size: 14px;
    }
`;

export default StyledHeader;
