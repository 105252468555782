import styled from 'styled-components';

const StyledDesksSummaryTab = styled.div`
    padding-top: 20px;
    .selectors-section {
        display: flex;
        align-items: end;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
    .ant-select-selector {
        height: 40px !important;
        width: 250px !important;
    }
    .ant-select-selection-item {
        top: 5px;
    }
    .charts {
        display: flex;
        align-items: center;
    }
`;

export default StyledDesksSummaryTab;
