import React from 'react';

import PropTypes from 'prop-types';

import Footer from './Footer';
import Header from './Header';
import PageHeading from './PageHeading';
import StyledMain from './styled/Main.styled';

const Main = ({
    hasHeader = true,
    hasHeading = true,
    hasNavigation = true,
    hasCustomHeading = false,
    hasFooter = false,
    customHeadingComponent,
    children
}) => (
    <>
        {hasHeader && <Header hasNavigation={hasNavigation} />}
        {hasHeading && <PageHeading />}
        {hasCustomHeading && <>{customHeadingComponent}</>}
        <StyledMain className="main">{children}</StyledMain>
        {hasFooter && <Footer />}
    </>
);

Main.propTypes = {
    hasHeader: PropTypes.bool,
    hasHeading: PropTypes.bool,
    hasNavigation: PropTypes.bool,
    hasCustomHeading: PropTypes.bool,
    hasFooter: PropTypes.bool,
    customHeadingComponent: PropTypes.element,
    children: PropTypes.node
};

export default Main;
