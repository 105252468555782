import React, { useState, useCallback } from 'react';

import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    homeWorkLocationId,
    officeWorkLocationId,
    absenceWorkLocationId,
    officeHomeMentorMaterId,
    marine80,
    yellow,
    remoteWorkLocationId,
    flexibleRemoteMentorMaterId,
    contractorMentorMaterId,
    grey
} from 'shared/constants/generalConstants';
import FlightIcon from 'shared/theme/assets/icons/FlightIcon';
import OfficeLocationModal from 'wfh/modals/Locations/OfficeLocationModal';
import profilesService from 'wfh/services/profilesService';

import {
    createWorkLocation,
    updateWorkLocation
} from '../../redux/slices/workLocation';
import DoneIcon from '../../shared/theme/assets/icons/DoneIcon';
import HomeIcon from '../../shared/theme/assets/icons/HomeIcon';
import OfficeIcon from '../../shared/theme/assets/icons/OfficeIcon';
import StyledLocationPicker from './styled/LocationPicker.styled';

const LocationPicker = ({
    workLocation,
    date,
    workLocationTypeId,
    isDefinedByUser,
    children,
    editable,
    passed,
    holiday
}) => {
    const dispatch = useDispatch();
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const currentUser = useSelector(state => state.profiles.currentProfile);
    let mentormaterId = profilesService.getMentomaterId(currentUser, date);

    const toggleLocationModal = useCallback(() => {
        setLocationModalOpen(() => !locationModalOpen);
    }, [locationModalOpen]);
    const isAnotherOffice =
        mentormaterId == officeHomeMentorMaterId &&
        workLocation[0]?.deskLocationId != null &&
        currentUser.location?.id != null &&
        workLocation[0]?.deskLocationId != currentUser.location.id;

    const [isRequestForAnotherOffice, setIsRequestForAnotherOffice] =
        useState();

    const onClickLocation = (targetWorkLocationTypeId, showModal) => {
        setIsRequestForAnotherOffice(showModal);
        if (
            (mentormaterId != officeHomeMentorMaterId &&
                targetWorkLocationTypeId == officeWorkLocationId) ||
            showModal
        ) {
            setLocationModalOpen(true);
        } else {
            selectWorkLocationHandler(targetWorkLocationTypeId, null);
        }
    };

    const selectWorkLocationHandler = (typeId, desk) => {
        if (
            workLocationTypeId != typeId ||
            (typeId == officeWorkLocationId &&
                (isAnotherOffice ||
                    isRequestForAnotherOffice ||
                    mentormaterId != officeHomeMentorMaterId))
        ) {
            const requestData = {
                workLocationTypeId: typeId,
                startDate: date,
                deskId: desk?.id
            };
            if (isDefinedByUser) {
                const id = workLocation[0].id;
                dispatch(updateWorkLocation({ id, ...requestData }));
            } else {
                dispatch(createWorkLocation(requestData));
            }
        }
        setLocationModalOpen(false);
    };

    const isRemoteMentormater =
        mentormaterId == flexibleRemoteMentorMaterId ||
        mentormaterId == contractorMentorMaterId;

    const isHoliday = holiday != null && !isRemoteMentormater;

    const items = [
        {
            key: 'home',
            label: (
                <StyledLocationPicker className="option" id="home">
                    <div className="work-from">Home</div>
                    {(workLocationTypeId === homeWorkLocationId ||
                        workLocationTypeId === remoteWorkLocationId) && (
                        <DoneIcon className="done" />
                    )}
                </StyledLocationPicker>
            ),
            icon: <HomeIcon />,
            onClick: () =>
                onClickLocation(
                    mentormaterId == flexibleRemoteMentorMaterId ||
                        mentormaterId == contractorMentorMaterId
                        ? remoteWorkLocationId
                        : homeWorkLocationId
                )
        },
        {
            key: 'office',
            label: (
                <StyledLocationPicker className="option" id="office">
                    <div
                        className={`work-from ${
                            holiday != null && isRemoteMentormater
                                ? 'disabled'
                                : ''
                        }`}
                    >
                        {mentormaterId == officeHomeMentorMaterId
                            ? 'Default Office'
                            : 'Office'}
                    </div>
                    {workLocationTypeId === officeWorkLocationId &&
                        !isAnotherOffice && <DoneIcon className="done" />}
                </StyledLocationPicker>
            ),
            icon: (
                <OfficeIcon
                    color={
                        holiday != null && isRemoteMentormater ? grey : yellow
                    }
                />
            ),
            onClick: () => onClickLocation(officeWorkLocationId),
            disabled: holiday != null && isRemoteMentormater
        },
        mentormaterId == officeHomeMentorMaterId && {
            key: 'another-office',
            label: (
                <StyledLocationPicker className="option" id="another-office">
                    <div className="work-from">Another Office</div>
                    {workLocationTypeId === officeWorkLocationId &&
                        isAnotherOffice && <DoneIcon className="done" />}
                </StyledLocationPicker>
            ),
            icon: <OfficeIcon color={marine80} />,
            onClick: () => onClickLocation(officeWorkLocationId, true)
        },
        {
            key: 'time-off',
            label: (
                <StyledLocationPicker className="option" id="time-off">
                    <div className="work-from">Time Off</div>
                    {workLocationTypeId === absenceWorkLocationId && (
                        <DoneIcon className="done" />
                    )}
                </StyledLocationPicker>
            ),
            icon: <FlightIcon />,
            onClick: () => onClickLocation(absenceWorkLocationId)
        }
    ];

    return (
        <>
            <Dropdown
                menu={{ items }}
                trigger={['click']}
                disabled={!editable || passed || isHoliday}
                destroyPopupOnHide={true}
            >
                {children}
            </Dropdown>
            <OfficeLocationModal
                visible={locationModalOpen}
                closeModal={toggleLocationModal}
                date={date}
                /* eslint-disable react/jsx-no-bind*/
                getDesk={desk =>
                    selectWorkLocationHandler(officeWorkLocationId, desk)
                }
                /* eslint-enable */
            />
        </>
    );
};

LocationPicker.propTypes = {
    workLocation: PropTypes.array,
    date: PropTypes.string,
    workLocationTypeId: PropTypes.number,
    isDefinedByUser: PropTypes.bool,
    children: PropTypes.element,
    editable: PropTypes.bool,
    passed: PropTypes.bool,
    holiday: PropTypes.object
};

export default LocationPicker;
