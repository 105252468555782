import React, { useEffect, useState, useCallback } from 'react';

import { Button } from 'antd';
import PropTypes from 'prop-types';

import StyledButtonGroup from './styled/ButtonGroup.styled';

const ButtonGroup = ({
    buttons,
    doSomethingAfterClick,
    defaultSelection,
    location
}) => {
    const [clicked, setClicked] = useState(defaultSelection);

    const handleClick = useCallback(
        event => {
            const id = event.currentTarget.value;
            setClicked(id);
            doSomethingAfterClick(id);
        },
        [doSomethingAfterClick]
    );

    useEffect(() => {
        setClicked(defaultSelection);
    }, [location]);

    return (
        <StyledButtonGroup>
            {buttons.map(button => (
                <Button
                    value={button}
                    key={button}
                    onClick={handleClick}
                    className={
                        button == clicked
                            ? 'customButton active'
                            : 'customButton'
                    }
                >
                    {button}
                </Button>
            ))}
        </StyledButtonGroup>
    );
};

ButtonGroup.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.string),
    doSomethingAfterClick: PropTypes.func,
    defaultSelection: PropTypes.string,
    location: PropTypes.object
};

export default ButtonGroup;
