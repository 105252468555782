import React, { useCallback, useEffect, useState } from 'react';

import { EditTwoTone } from '@ant-design/icons';
import { Button, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    adminActions,
    getLocationsWithNotifications
} from 'redux/slices/admin';
import { darkNavy } from 'shared/constants/generalConstants';

import LocationNotificationsModal from './LocationNotificationModal';
import StyledNotificationsTab from './styled/NotificationsTab.styled';

const NotificationsTab = () => {
    const dispatch = useDispatch();
    const { notifications, isLoading } = useSelector(state => state.admin);
    const [editLocationId, setEditLocationId] = useState();
    const [locationModalOpen, setLocationModalOpen] = useState(false);

    useEffect(() => {
        dispatch(getLocationsWithNotifications());
        return () => {
            dispatch(adminActions.clearNotifications());
        };
    }, []);

    const toggleLocationModal = useCallback(() => {
        setLocationModalOpen(() => !locationModalOpen);
    }, [locationModalOpen]);

    const openModal = useCallback(event => {
        setEditLocationId(event.currentTarget.value);
        toggleLocationModal();
    }, []);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
            width: 150
        },
        {
            title: 'Emails',
            dataIndex: 'locationNotifications',
            key: 'locationNotifications',
            render: (_, { locationNotifications }) => (
                <>
                    {locationNotifications.map(location => {
                        return (
                            <Tag color={darkNavy} key={location.emailAddress}>
                                {location.emailAddress.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            )
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, { key }) => (
                <Button
                    icon={<EditTwoTone twoToneColor={darkNavy} />}
                    size="large"
                    shape="circle"
                    onClick={openModal}
                    value={key}
                ></Button>
            ),
            width: 50
        }
    ];

    const tableData = notifications?.map(x => ({
        key: x.id,
        name: x.name,
        region: x.region,
        locationNotifications: x.locationNotifications
    }));

    return (
        <StyledNotificationsTab>
            <Table
                dataSource={tableData}
                columns={columns}
                bordered
                loading={isLoading}
                rowKey="key"
                pagination={false}
            ></Table>
            {locationModalOpen && (
                <LocationNotificationsModal
                    visible={locationModalOpen}
                    closeModal={toggleLocationModal}
                    locationId={editLocationId}
                />
            )}
        </StyledNotificationsTab>
    );
};

export default NotificationsTab;
