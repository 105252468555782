import styled from 'styled-components';

const StyledCustomSelect = styled.div`
    .custom-select-div {
        min-width: 240px;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 456px;
        height: 50px;
        background: var(--primaryWhite);
        border: 1px solid var(--gray);
        box-sizing: border-box;
        border-radius: 8px;
    }
    .ant-select-disabled.ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        color: var(--black);
        background: #f5f5f5;
    }
    .ant-select-selection-search-input {
        width: 226px !important;
    }
    .custom-select-div .custom-select-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
    }
    .ant-select-selection-placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }
    .ant-select-selection-item {
        margin-top: 0px;
    }
    .ant-select-item-option {
        font-family: 'Source-Sans-Pro';
        font-size: 14px;
    }
    .ant-select-arrow {
        top: 50%;
    }
    .ant-select-arrow svg rect {
        fill: none;
    }
    .ant-select-selection-search {
        display: flex;
        align-items: center;
    }
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
        pointer-events: none;
    }
    .ant-select-clear {
        display: flex;
        align-items: center;
    }
    .anticon > svg {
        fill: var(--red);
    }
    .required {
        color: red;
        margin-right: 5px;
    }
`;

export { StyledCustomSelect };
