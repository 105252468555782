import url from '../../apiUrls';
import httpService from '../../shared/services/httpService';

const getGroupById = async groupId => {
    const data = await httpService.get(url.GET_GROUP_BY_ID(groupId));
    return data.data;
};

const getGroups = async () => {
    const data = await httpService.get(url.GET_GROUPS());
    return data.data;
};

const createGroup = async requestData => {
    const data = await httpService.post(url.CREATE_GROUP(), {
        ...requestData
    });
    return data.data;
};

const updateGroup = async (groupId, requestData) => {
    const data = await httpService.put(url.UPDATE_GROUP(groupId), {
        ...requestData
    });
    return data.data;
};

const deleteGroup = async groupId => {
    const data = await httpService.delete(url.DELETE_GROUP(groupId));
    return data.data;
};

export default {
    getGroupById,
    getGroups,
    createGroup,
    updateGroup,
    deleteGroup
};
