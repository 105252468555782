import DesksSummaryTab from 'office/components/DesksSummaryTab';

const officeTabs = [
    {
        label: 'Desks Summary',
        value: 1,
        disabled: false,
        child: props => <DesksSummaryTab />
    }
];

export default officeTabs;
