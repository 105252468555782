import styled from 'styled-components';

const StyledNotificationsTab = styled.div`
    padding-top: 20px;

    .ant-table {
        padding-top: 20px;
        border-radius: 4px;
    }

    .ant-table-thead > tr > th {
        background: var(--marine5) !important;
    }

    .ant-spin-dot-item {
        background-color: var(--orange);
    }
`;

export default StyledNotificationsTab;
